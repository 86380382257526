.pageTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: black;
  font-family: "Poppins", sans-serif;
  font-family: "Poppins", sans-serif;
  margin-left: 50px;
}

.pageSubTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: black;
  font-family: "Poppins", sans-serif;
  margin-left: 50px;
}
.detailContainer {
  margin-top: 24px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
}

/* main */
.container {
  display: flex;
  flex-direction: row;
}

.leftContainer {
  width: 90%;
  height: 100%;
  /* border: 1px solid black; */
  background-color: white;
  border-right: 2px solid #f3f4f5;
  min-height: calc(100vh - 60px);
}

h4 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

/* main detail */

/* Perizinan Fullname */
.perizinanFullname {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.inputFullname {
  border: 1px solid #c4c4c4;
  width: 60%;
  height: 35px;
  border-radius: 12px;
  padding: 5px;
}

/* Perizinan Departemen */
.perizinanDepartemen {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.inputDepartemen {
  border: 1px solid #c4c4c4;
  width: 60%;
  height: 35px;
  border-radius: 12px;
  padding: 5px;
}

/* Jenis Perizinan */
.jenisPerizinan {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.inputJenisPerizinan {
  border: 1px solid #c4c4c4;
  width: 60.5%;
  height: 40px;
  border-radius: 12px;
  padding: 5px;
}

/* Tanggal Perizinan */

.waktuPerizinan {
  display: flex;
  flex-direction: row;
  margin-left: 50px;
}

.tglMulaiPerizinan {
  display: flex;
  flex-direction: column;
}

.inputTanggalMulai {
  border: 1px solid #c4c4c4;
  width: 100%;
  height: 35px;
  border-radius: 12px;
  padding: 5px;
}

.tglAkhirPerizinan {
  display: flex;
  flex-direction: column;
}

.inputTanggalAkhir {
  border: 1px solid #c4c4c4;
  width: 100%;
  height: 35px;
  border-radius: 12px;
  padding: 5px;
}

/* Lampiran Perizinan */
.lampiran {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.inputLampiran {
  border: 1px solid #c4c4c4;
  width: 60%;
  height: 35px;
  border-radius: 12px;
  padding: 5px;
}

/* Keterangan Perizinan */
.keterangan {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.inputKeterangan {
  border: 1px solid #c4c4c4;
  width: 60%;
  height: 120px;
  border-radius: 12px;
  padding: 5px;
}

/* label {
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
font-family: 'Poppins', sans-serif;
} */

.labelKeterangan {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: "Poppins", sans-serif;
}

.btnPerizinan {
  /* padding: 15px 20px; */
  width: 165px;
  height: 55px;
  background: #004cbc;
  border-radius: 12px;
  margin-left: 50px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
}

.radioBtn {
  display: flex;
  flex-direction: row;
}

.icon {
  position: absolute !important;
  width: 24px;
  height: 24px;
  color: #c4c4c4;
  margin-top: 40px;
  margin-left: 410px;
}

/* Right*/
.rightContainer {
  width: 35%;
  background-color: rgba(0, 76, 190, 0.02);
  padding: 10px;
  min-height: calc(100vh - 60px);
}

.rightTitle {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
}

.calendar {
  width: 302px;
  height: 288px;
}
@media screen and (max-width: 1024px) {
  .container {
    width: 1150px;
  }
}

/* For screens smaller than 768px (mobile devices) */
@media (max-width: 767px) {
  .container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    margin-bottom: 80px;
  }

  .leftContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-right: none;
    margin-top: 40px;
    padding: 0 25px;
    position: relative;
  }

  .perizinanFullname,
  .perizinanDepartemen,
  .jenisPerizinan,
  .waktuPerizinan,
  .lampiran,
  .keterangan {
    margin-left: 10px;
  }

  .inputFullname,
  .inputDepartemen,
  .inputJenisPerizinan,
  .inputTanggalMulai,
  .inputTanggalAkhir,
  .inputLampiran,
  .inputKeterangan {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .btnPerizinan {
    margin-left: 10px;
  }

  .icon {
    margin-top: 40px;
    margin-left: calc(50% - 12px);
  }

  .rightContainer {
    /* width: 100%; */
    display: none;
  }

  .calendar {
    /* width: 100%;
    height: 300px;
    margin-top: 20px; */
    display: none;
  }
}

/* For screens larger than 768px (desktop devices) */
@media (min-width: 768px) {
  .leftContainer {
    width: 70%;
    min-height: calc(100vh - 60px);
  }

  .rightContainer {
    width: 30%;
    min-height: calc(100vh - 60px);
  }

  .calendar {
    /* margin-top: 0; */
    display: none;
  }
}
