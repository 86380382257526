*{
    border: none;
  }
  .pageTitle {
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 48px;
      color: black;
      font-family: 'Poppins', sans-serif;
      margin-left: 50px;
    }
  
    .pageSubTitle {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: black;
      font-family: 'Poppins', sans-serif;
      margin-left: 50px;
    }
    .detailContainer {
      margin-top: 24px;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      
    }
    
  
    /* main */
    .container {
      display: flex;
      min-height: calc(100vh - 60px);
    }
    
    .leftContainer {
      width: 100%;
      background-color: white;
      border-right: 2px solid #f3f4f5;
      min-height: calc(100vh - 60px);
    }
    
  h4 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-left: 50px;
  }
  
    /* main detail */
    .inputKeterangan  {
      border: 1px solid #C4C4C4;
      width: 70%;
      height: 200px;
      border-radius: 12px;
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      font-weight: 400;
      color: black;
      margin-left: 50px;
      padding: 10px;
    }
    
  
  .btnLapKeg_active2 {
    padding: 15px 20px;
    /* background: #004CBC; */
    background: black;
    border-radius: 12px;
    color: white;
  }
  
  .btnLapKeg {
    padding: 15px 20px;
    background: blue;
    border-radius: 12px;
    color: white;
  }
   
    /* status */
    .rightContainer {
      width: 35%;
      background-color: rgba(0, 76, 190, 0.02);;
      padding: 10px;
      min-height: calc(100vh - 60px);
    }
  
    .calendar {
      width: 302px; 
       height: 100%;
  
    }
    @media screen and (max-width: 1024px) {
      .container {
        width: 1150px;
      }
    }
    