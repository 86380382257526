.wrapper {
  width: 250px;
  height: calc(100vh - 60px);
  overflow-y: auto;
  background-color: rgba(0, 76, 190, 0.02);
  position: fixed;
  z-index: 1;
  top: 70px;
  align-items: center;
  display: flex;
  flex-direction: column;
  user-select: none;
  border-right: 5px solid #f3f4f5;
  position: fixed;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 24px 0px 0px 0px;
  flex: 1;
}

.linkSidebar {
  text-decoration: none;
  color: #181818;
  display: flex;
  align-items: center;
  gap: 15px;
  padding-left: 24px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 24px;
}

.linkSidebarActive {
  text-decoration: none;
  color: #004cbc;
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 24px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 24px;
  border-radius: 10px;
  background-color: #e6edf8;
}

.linkSidebarActive:active {
  text-decoration: none;
  color: #004cbc;
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 24px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 24px;
  border-radius: 10px;
  background-color: #e6edf8;
}

.text {
  font-weight: 400;
  font-size: 16px;
  color: black;
  font-family: "Poppins", sans-serif;
}

.textColor {
  font-weight: 400;
  font-size: 16px;
  color: #004cbc;
  /* color: #004CBC; */
  font-family: "Poppins", sans-serif;
}

.linkSidebar2 {
  text-decoration: none;
  color: #181818;
  display: flex;
  align-items: center;
  gap: 15px;
  padding-left: 24px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 24px;
}

.linkSidebarActive2 {
  text-decoration: none;
  color: #004cbc;
  display: flex;
  align-items: center;

  padding-left: 24px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 24px;
  border-radius: 10px;
  background-color: #e6edf8;
}

.text2 {
  font-weight: 400;
  font-size: 16px;
  color: black;
  font-family: "Poppins", sans-serif;
}

.textColor2 {
  font-weight: 400;
  font-size: 16px;
  color: #004cbc;
  /* color: #004CBC; */
  font-family: "Poppins", sans-serif;
}

.dropdown {
  position: relative;
}

/* breakpoints */
/* tablet */
/* @media (max-width: 900px) {
  .container {
    width: 100%;
    margin: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
  }
  .left {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .logo{
    width: 150px;
    height: 60px;
  }

  .right {
    display: flex;
    align-items: center;
    margin-right: 5px;
    gap: 16px;
  }
  
} */
@media screen and (max-width: 1024px) {
  .wrapper {
    width: 100px;
    /* position: fixed; */
  }
  .text {
    display: none;
  }

  .textColor {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .wrapper {
    width: 100%;
    height: 60px;
    bottom: 0;
    top: auto;
    border-right: none;
    position: fixed;
    z-index: 5;
    background-color: none;
  }
  .container {
    margin: 0;
    padding: 16px;
    flex-direction: row;
    width: 100%;
    /* height: 60px; */
    bottom: 0;
    top: auto;
    border-right: none;
    border-top: 5px solid #f3f4f5;
    position: fixed;
    background-color: white;
    z-index: 5;
  }
  .linkSidebar {
    padding: 10px 0;
    justify-content: center;
    width: 100%;
    border-bottom: none;
    flex: 1;
  }
  .linkSidebarActive {
    padding: 10px 0;
    justify-content: center;
    width: 100%;
    border-radius: 0;
    background-color: #e6edf8;
    flex: 1;
  }
  .linkSidebarActive:active {
    background-color: #e6edf8;
  }
  .linkSidebar2 {
    display: none;
  }

  .dropdown {
    display: block;
    width: 100%;
  }

  .listWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
  }

  .list_child {
    top: -100%;
    padding: 0;
  }
}
