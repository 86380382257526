.modal-wrapper {
    background-color: black;
    opacity: 0.5;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: auto;
}

.modal-box {
background-color: #fff;
width: 500px;
margin: 20px auto;
border-radius: 5px;
}

.modal-box-header{
padding: 10px;
display: flex;
justify-content: space-between;
border-bottom: 1px solid grey;    
}

.modal-box-tittle {
    
}
.modal-box-close {}
    
.modal-content {
    padding: 10px;
}
.modal-footer {
    padding: 10px;
    border-top: 1px solid grey;
    display: flex;
    justify-content: end;
    gap: 5px;
}