.pageNumbers {
  list-style: none;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  justify-content: flex-end;
}

.pageNumbers li {
  padding: 7px 13px;
  border: 1px solid white;
  cursor: pointer;
  border-radius: 50%;
  color: black;
  align-items: center;
}

.pageNumbers li.active {
  border: 1.5px solid #004cbc;
  border-radius: 6px;
  align-items: center;
}

.pageNumbers li button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}
.pageNumbers li button img {
  width: 30px;
  height: 30px;
  margin-top: 10px;
  margin-left: 30px;
}
.pageNumbers li button:hover {
  background-color: white;
  color: black;
}
.pageNumbers li button:focus {
  outline: none;
}

.loadmore {
  /* padding: 1rem; */
  background-color: transparent;
  color: white;
  font-size: 1.2rem;
  border: 1px solid white;
  cursor: pointer;
}

/* .wrap_select_filter {
  padding: 10px;
} */
.wrap_select_filter label {
  padding-right: 10px;
  font-size: 14px;
}
