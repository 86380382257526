.wrapper {
  background-color: rgba(0, 0, 0, 0.5);
  /* background-color: wheat; */
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: none;
  z-index: 3;
  display: flex;
  flex-direction: column;
  z-index: 100;
  justify-content: center;
  align-items: center;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 90%;
  width: 50%;
  /* justify-content: center;
  align-items: center; */
  border-radius: 20px;
  overflow-x: scroll;
}

.topWrapper {
  margin-top: 20px;
  color: #c4c4c4;
  width: 20px;
  height: 10px;
  margin-left: 20px;
}

.topFormWrapper {
  display: flex;
  flex-direction: column;
  padding: 52px 42px;
}

.formTitle {
  display: flex;
  flex-direction: column;
}

.title {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #b0b0b0;
}

.isititle {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 36px;
  color: #000000;
}
.contentFormWrapper {
  box-sizing: border-box;
  /* border: 1px solid black; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 42px;
  margin-bottom: 10px;
}
.topKotak {
  display: flex;
  width: 100%;
  height: 44px;
  /* height: 20%; */
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #004cbc;
  border-radius: 20px 20px 0px 0px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.isiKotak {
  gap: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 0px 0px 20px 20px;
}

.spanKotak {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #b0b0b0;
}

.isispanKotak {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-left: 20px;
}

.image {
  width: 100%;
  height: 200px;
}

.imageKotak {
  width: 30%;
  /* border: 1px solid black; */
}

.isiKotaknya {
  width: 70%;
  /* border: 1px solid black; */
}
