@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* align-items: center; */
  /* z-index: 1; */
  /* top: 0; */
  /* border: 3px solid black; */
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 50px;
  height: 80px;
  background-color: #fff;
  width: 100%;
  box-shadow: 0px 4px 30px rgba(176, 200, 234, 0.3);
  background: #ffffff;
  position: fixed;
  z-index: 2;
  box-sizing: border-box;
}

.leftNavbar {
  display: flex;
  align-items: center;
}

.logo {
  width: 200px;
  height: 57px;
}

.centerNavbar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentLI {
  margin: 0 10px;
  list-style: none;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

.rightNavbar {
  display: flex;
  align-items: center;
}

.bttnLoginNavbar {
  background: #ffffff;
  border: 1px solid #e6edf8;
  border-radius: 8px;
  padding: 10px 20px;
  margin-right: 10px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  align-items: center;
  text-align: center;
  line-height: 21px;
  color: #000000;
  cursor: pointer;
}

.bttnSignupNavbar {
  background: #004cbc;
  border-radius: 8px;
  padding: 10px 20px;
  color: white;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  align-items: center;
  text-align: center;
  line-height: 21px;
  cursor: pointer;
}

.LinkLI {
  text-decoration: none;
  color: #333;
}

.navToggle {
  display: none;
}

@media screen and (max-width: 768px) {
.navbar {
  display: flex;
  justify-content: center;
}
  .centerNavbar {
    display: none;
  }

  .logo {
    width: 100px;
    height: 40px;
    margin-right: 10px;
  }

  .navToggle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .navOpen {
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease-in-out;
    transform: translateX(0%);
  }

  .contentLI {
    margin: 10px 0;
    font-size: 1.2rem;
    text-align: center;
  }

  .navOpen .contentLI {
    opacity: 0;
    animation: fadeIn 0.5s ease forwards;
    animation-delay: 0.3s;
  }

  .navOpen .contentLI:nth-child(1) {
    animation-delay: 0.4s;
  }

  .navOpen .contentLI:nth-child(2) {
    animation-delay: 0.5s;
  }

  .navOpen .contentLI:nth-child(3) {
    animation-delay: 0.6s;
  }

  .navOpen .contentLI:nth-child(4) {
    animation-delay: 0.7s;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }
}
/*CONTENT PRICING*/
.LinkLI {
  text-decoration: none;
}

.wrapperSK {
  width: 100%;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.h2titleSK {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  display: flex;
  text-align: center;
  background: linear-gradient(101.11deg, #0763B0 8.67%, #379EF5 89.51%, #379EF5 89.51%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-left: 85px;
}

.subTitleSK {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  text-align: justify;
  color: #000000;
  width: 850px;
  margin-left: 85px;
}

.definisi {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  color: #000000;
  margin-left: 85px;
}

.li1 {
  width: 825px;
  margin-left: 115px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  text-align: justify;
  color: #000000;
}

.li2 {
  width: 825px;
  margin-left: 20px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  text-align: justify;
  color: #000000;
}

/* Media Query for 768px */
@media only screen and (max-width: 768px) {
  .h2titleSK {
    font-size: 24px;
    line-height: 36px;
    margin-left: 0;
  }

  .subTitleSK {
    width: auto;
    margin-left: 0;
  }

  .definisi {
    font-size: 16px;
    line-height: 24px;
    margin-left: 0;
  }

  .li1,
  .li2 {
    width: auto;
    margin-left: 0;
  }
}

