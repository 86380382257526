.wrapper {
  width: 250px;
  min-height: calc(100vh - 60px);
  overflow-y: auto;
  background-color: rgba(0, 76, 190, 0.02);
  position: fixed;
  z-index: 1;
  top: 70px;
  align-items: center;
  display: flex;
  flex-direction: column;
  user-select: none;
  border-right: 5px solid #f3f4f5;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 24px 0px 0px 0px;
  flex: 1;
}

.linkSidebar {
  text-decoration: none;
  color: #181818;
  display: flex;
  align-items: center;
  gap: 15px;
  padding-left: 24px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 24px;
}

.linkSidebarActive {
  text-decoration: none;
  color: #004CBC;
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 24px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 24px;
  border-radius: 10px;
  background-color: #E6EDF8;
}

.text {
  font-weight: 400;
  font-size: 16px;
  color: black;
  font-family: 'Poppins', sans-serif;
}

.textColor {
  font-weight: 400;
  font-size: 16px;
  color: #004CBC;
  /* color: #004CBC; */
  font-family: 'Poppins', sans-serif;
}


.linkSidebar2 {
  text-decoration: none;
  color: #181818;
  display: flex;
  align-items: center;
  gap: 15px;
  /* padding-left: 50px;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-right: 54px; */
}

.linkSidebarActive2 {
  text-decoration: none;
  color: #004CBC;
  display: flex;
  align-items: center;
 
  /* padding-left: 50px;
  padding-top: 58px;
  padding-bottom: 58px;
  padding-right: 50px; */
  border-radius: 10px;
  background-color: #E6EDF8;
}

.text2 {
  font-weight: 400;
  font-size: 16px;
  color: black;
  font-family: 'Poppins', sans-serif;
}

.textColor2 {
  font-weight: 400;
  font-size: 16px;
  color: #004CBC;
  /* color: #004CBC; */
  font-family: 'Poppins', sans-serif;
}


/* breakpoints */
/* tablet */
@media screen and (max-width: 1024px) {
  .wrapper {
    width: 100px;
    position: fixed;
  }
  .text {
    display: none;
  }

  .textColor {
    display: none;
  }

}
