
.topinformasiProfile span {
    margin-top: 150px !important;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 50px;
    color: #000000;
    margin-left: 100px;
}

.topinformasiProfile {
    margin-top: 30px;
}

.border {
    margin-top: 30px;
    border-bottom: 1px solid #c4c4c4;
    width: 995px;
    margin-left: 100px;
}

.formProfile {
    margin-left: 100px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
}

.formProfile span {
    font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 28px;
color: #000000;
}

.profileFullname {
    display: flex;
    flex-direction: column;
  }
  
  label {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    }
    
.inputFullname  {
    width: 40%;
    height: 40px;
    border-radius: 16px;
    padding: 5px;
    background: #FFFFFF;
    border: 1px solid #004CBC;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
}

.buttonProfile {
    width: 41.5%;
    height: 55px;
    background: #004CBC;
border-radius: 14px;
    margin-bottom: 50px;
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 28px;
text-align: center;
color: #FFFFFF;
margin-left: -1px;
}