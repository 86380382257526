.wrapper{
    display: flex;
    flex-direction: column;
    background: #004CBC;
    min-height: calc(100vh);
}
.wrapperTop{
    margin-top: 40px;
    /* padding:20px 10px 40px 0px; */
    background: #B0C8EA;
    border-radius: 20px;
    width: 1000px;
    height: 70px;
    margin-left: auto;
    margin-right: auto;
}
.wrapperTop p{
    font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 30px;
color: #000000;
align-items: center;
text-align: left;
margin-left: 20px;
margin-top: 20px;
}

.wrapperTop span {
font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 30px;
color: #000000;
margin-left: 5px;
}

.wrapperContent{
    background: white;
    border-radius: 20px;
    width: 1000px;
    height: 640px;
    margin-bottom: 40px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
}

/* Left*/

.leftWrapper{
flex-basis: 50%;
border-right: 2px solid #B0B0B0;
/* margin-left: 70px; */
/* margin-right: 70px; */
margin-top: 50px;
margin-bottom: 50px;
}

.formLeftWrapper{
display: flex;
flex-direction: column;
margin-left: 70px;
}

.leftTitleWrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right:50px ;
}

.leftTitleWrapper img{
    width: 60px;
    height: 40px;
}

.leftTitleTopWrapper h4{
text-align: right;
}
.leftTitleTopWrapper{
    display: flex;
    flex-direction: column;
}

.leftSubTitleWrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right:50px ;
    margin-left: -50px;
}

.leftcontentWrapperFirst {
    margin-left: -50px;
    display: flex;
    flex-direction: row;
}

.leftcontentWrapperFirst h4 {

    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
}

.leftcontentWrapper {
    display: flex;
    flex-direction: row;

}

.inputContentWrapper{
    display: flex;
    flex-direction: row;
    border: 1px solid #B0B0B0;
    border-radius: 18px;
    height: 50px;
    align-items: center;
    width: 352px;
}

.inputContentWrapper p {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    margin-left: 20px;
}

.inputContentWrapper span {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    color: #1277EA;
    margin-left: 150px;
}

 .danger img{
    width: 352px;
    margin-top: -10px;
 }
 .dangerText {
    position: absolute;
    margin-top: -50px;
    max-width: 352px;
    margin-left: 12px;
 }
 .dangerText p{
    font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 21px;
color: #000000;
 }

 .dangerText span {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #000000;  
 }

 .textTransaksi h3 {
    font-family: 'Poppins' sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
 }

 .textTransaksi p{
    font-family: 'Poppins' sans-serif;
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
color: #000000;
width: 352px;
text-align: left;
 }

 .buttonBlue {
    background: #004CBC;
    border-radius: 18px;
    width: 352px;
    height: 60px;
    margin: auto;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    color: #FFFFFF;
 }

 .buttonWhite{
    background-color: white;
    border: 1px solid #B0C8EA;
    border-radius: 18px;
    width: 352px;
    height: 60px;
    margin: auto;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    color: black;
 }
/* 
 .segitigabiadap {
    width: 10px;
    height: 10px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid black;
    clip-path: polygon(50% 0, 100% 100%, 0 100%);
    background-color: #000000;
 } */

/*Right*/
.rightWrapper{
    flex-basis: 50%;
    margin-right: 25px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.inputPetunjukWrapper{
    display: flex;
    flex-direction: row;
    border: 1px solid #B0B0B0;
    border-radius: 18px;
    height: 50px;
    align-items: center;
    width: 400px;
    margin-left: 50px;
}

.inputPetunjukWrapper p{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #000000;
    margin-left: 12px;
}

.listpetunjuk  {
    list-style-type:lower-roman;
    margin-left: 100px;
    width: 352px;
}

ol li {
    list-style-type: upper-number;
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: #000000;
width: 352px;
}

.leftcontentWrapper {
    display: flex;
    flex-direction: row;
    user-select: none;
}

.inputContentWrapperVA {
    display: flex;
    flex-direction: row;
    align-items: center;
    user-select: none;
}
.inputContentWrapperVA p {
    border: 1px solid #B0B0B0;
    border-radius: 18px;
    height: 30px;
    align-items: center;
    width: 330px;
    padding: 10px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}

.inputContentWrapperVA span {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #1277EA;
    margin-left: -60px;
    cursor: pointer;
}


.inputContentWrapperVA{
    display: flex;
    flex-direction: row;
    align-items: center;
    user-select: none;
}

.inputContentWrapperVA p {
    border: 1px solid #B0B0B0;
    border-radius: 18px;
    height: 30px;
    align-items: center;
    width: 330px;
    padding: 10px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}

.inputContentWrapperVA span {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #1277EA;
    margin-left: -60px;
    cursor: pointer;
}

.iconDropdown{
    height: 20px;
    width: 20px;
    align-items: center;
    margin-left: 10px;
    color: #c4c4c4;
}

.iconDropdown2{
    height: 20px;
    width: 20px;
    align-items: center;
    margin-left: 15px;
    color: #c4c4c4;
}

.iconDropdown3{
    height: 20px;
    width: 20px;
    align-items: center;
    margin-left: 90px;
    color: #c4c4c4;
}