.wrapper {
  display: flex;
  flex-direction: column;
}

.videoCamera {
  position: absolute;
  align-items: center;
  width: 100%;
  /* height: 720px;*/
  height: 100%;
  top: 0;
  transform: rotateY(180deg);
  -webkit-transform:rotateY(180deg); /* Safari and Chrome */
  -moz-transform:rotateY(180deg); /* Firefox */
}

.rectangleCamera {
  width: 100%;
  height: 75px;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  color: #ffffff;
  align-items: center;
  display: flex;
  justify-content: center;
}

.wrapperCamera {
  display: flex;
  flex-direction: row;
}

.btnTakePicture {
  display: flex;
  align-items: end;
  justify-content: end;
  right: 0;
  position: absolute;
}

.btnClockIn {
  background: none;
  color: #ffffff;
  z-index: 100;
  position: absolute;
  top: 0;
  margin-top: 10px;
  font-size: 50px;
  margin-left: 20px;
}

.btnTakePicture {
  font-size: 90px;
  background: none;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 50%;
  margin-right: 130px;
}

canvas {
  display: none;
}

@media (max-width: 768px) {
  .btnTakePicture {
    top: auto;
    bottom: 10%;
  }
}
