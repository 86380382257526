@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
.wrapper {
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* align-items: center; */
  /* z-index: 1; */
  /* top: 0; */
  /* border: 3px solid black; */
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 50px;
  height: 80px;
  background-color: #fff;
  width: 100%;
  box-shadow: 0px 4px 30px rgba(176, 200, 234, 0.3);
  background: #ffffff;
  position: fixed;
  z-index: 2;
  box-sizing: border-box;
}

.leftNavbar {
  display: flex;
  align-items: center;
}

.logo {
  width: 200px;
  height: 57px;
}

.centerNavbar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentLI {
  margin: 0 10px;
  list-style: none;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

.rightNavbar {
  display: flex;
  align-items: center;
}

.bttnLoginNavbar {
  background: #ffffff;
  border: 1px solid #e6edf8;
  border-radius: 8px;
  padding: 10px 20px;
  margin-right: 10px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  align-items: center;
  text-align: center;
  line-height: 21px;
  color: #000000;
  cursor: pointer;
}

.bttnSignupNavbar {
  background: #004cbc;
  border-radius: 8px;
  padding: 10px 20px;
  color: white;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  align-items: center;
  text-align: center;
  line-height: 21px;
  cursor: pointer;
}

.LinkLI {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 27px;
display: flex;
align-items: center;
color: #004CBC;
text-decoration: none;
}

.navToggle {
  display: none;
}

@media screen and (max-width: 768px) {
.navbar {
  display: flex;
  justify-content: center;
}
  .centerNavbar {
    display: none;
  }

  .logo {
    width: 100px;
    height: 40px;
    margin-right: 10px;
  }

  .navToggle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .navOpen {
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease-in-out;
    transform: translateX(0%);
  }

  .contentLI {
    margin: 10px 0;
    font-size: 1.2rem;
    text-align: center;
  }

  .navOpen .contentLI {
    opacity: 0;
    animation: fadeIn 0.5s ease forwards;
    animation-delay: 0.3s;
  }

  .navOpen .contentLI:nth-child(1) {
    animation-delay: 0.4s;
  }

  .navOpen .contentLI:nth-child(2) {
    animation-delay: 0.5s;
  }

  .navOpen .contentLI:nth-child(3) {
    animation-delay: 0.6s;
  }

  .navOpen .contentLI:nth-child(4) {
    animation-delay: 0.7s;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }
}

/*CONTENT ONE*/

.contentOne {
  display: grid;
  grid-template-areas: "co-left co-right";
  grid-template-columns: 1fr 1fr;
  width: 100%;
  position: relative;
  margin-top: 90px;
  height: 646px;
  background: #ffffff;
  /* display: flex; */
  /* border: 1px solid black; */
}

.leftContentOne {
  grid-area: co-left;
  /* width: 50%; */
  /* display: flex; */
  /* flex-direction: column; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin-top: 103px;
}

.rightContentOneIMG {
  grid-area: co-right;
  /* width: 50%; */
  margin-top: -35px;
}

.wrapperKolaborasi {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 40px;
  left: 108px;
}

.textKolaborasi {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #999999;
}
.kolaborasi {
  /* position: absolute; */
  display: flex;
}
#mkalogo {
  margin-top: 20px;
  filter: grayscale(100%);
  height: 38px;
  width: 101px;
}
#mkalogo:hover {
  filter: grayscale(0%);
}

#kmlogo {
  margin-top: 20px;
  filter: grayscale(100%);
  height: 31px;
  width: 58px;
}

#kmlogo:hover {
  filter: grayscale(0%);
}

#dilologo {
  margin-top: 20px;
  filter: grayscale(100%);
  height: 38px;
  width: 80px;
}
#dilologo:hover {
  filter: grayscale(0%);
}

.leftTitleContentOne {
  text-align: left;
  /* margin-top: 20px; */
  margin-left: -133px;
  /* display: inline-flex; */
}

.titleBergerak {
  position: relative;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 57px;
  /* color: #000000; */
  color: #0763b0;
}

.titleContentOne {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 57px;
  color: #000000;
  position: relative;
}

.titleBergerak:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  /* border-left: 2px solid blue; */
  animation: animate 4s steps(16) infinite;
  /* z-index: 1; */
}

@keyframes animate {
  40%,
  60% {
    left: 100%;
  }
  100% {
    left: 0%;
  }
}

.leftSubTitleContentOne {
  width: 537px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 57px;
  color: #000000;
  margin-left: 108px;
  /* visibility: hidden; */
}

.buttonContentOne {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  width: 190px;
  height: 50px;
  background: #004cbc;
  box-shadow: 6px 4px 25px rgba(0, 76, 188, 0.3);
  border-radius: 12px;
  margin-left: -228px;
  margin-top: 50px;
  cursor: pointer;
}

.blush {
  position: absolute;
  width: 200px;
  height: 167px;
  margin-left: -120px;
  margin-top: 120px;
  background: #004cbc;
  opacity: 0.6;
  filter: blur(125px);
}

@media only screen and (max-width: 768px) {
  .contentOne {
  /* grid-template-areas: "co-left" "co-right";
  grid-template-columns: 1fr; */
  height: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  }

  
  .leftContentOne {
    /* margin-top: -103px; */
    display: flex;
    flex-direction: column;
    margin-top: auto;
    width: 100%;
    align-items: baseline;
  }
  
  .leftSubTitleContentOne {
    margin-left: 20px;
  font-size: 28px;
  line-height: 48px;
  width: 85%;
  }
  
  .leftTitleContentOne {
  align-items: start;
  text-align: center;
  font-size: 28px;
  line-height: 48px;
  }

  .titleContentOne {
    font-size: 28px;
    line-height: 48px;
    width: 100%;
    /* margin-left: 9px; */
  }

  .titleBergerak{
    font-size: 28px;
    line-height: 48px;
    width: 100%;
    
  }
  
  .blush {
  display: none;
  }
  
  .buttonContentOne {
  margin-left: 0px;
  margin-top: 20px
  }
  
  .wrapperKolaborasi {
    /* margin-top: 70px; */
  position: absolute;
  /* margin-top: 40px; */
  bottom: 40px;
  left: 10px;
  }
  
  #mkalogo {
  margin-top: 0px;
  margin-right: 10px;
  }
  
  #kmlogo {
  margin-top: 0px;
  margin-right: 10px;
  }
  
  #dilologo {
  margin-top: 0px;
  margin-right: 10px;
  }
  }


@media only screen and (max-width: 1280px) {
  .rightContentOneIMG {
    display: none;
    }
  
  .leftContentOne {
      display: flex;
      flex-direction: column;
  }
}

/*CONTEMT TWO*/
.contentTwo {
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: row;
  height: 534px;
  background: #e6edf8;
  /* border: 1px solid black; */
  align-items: center;
  gap: 90px;
}

.imgHP {
  position: absolute;
  margin-left: -270px;
  margin-top: -20px;
}

.rightContentTwo {
  display: flex;
  flex-direction: column;
  gap: 23px;
  padding: 0 20px 0 20px;
}

.rightContentTwoH3 {
  width: 417px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #000000;
}

.rightContentTwoP {
  width: 566px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

/* Content 3*/

.contentThree {
  box-sizing: border-box;
  width: 100%;
  /* height: 970px; */
  background: #ffffff;
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  padding: 63px 151px;
}

.titleContentThree {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.titleCT3 {
  margin-top: 63px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #004cbc;
}

.subTitleCT3 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  line-height: 57px;
  text-align: center;
  color: #000000;
}

.contentPage3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  list-style: none;
  gap: 30px;
}

.licontentPage3 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  /* color: #004cbc; */
  color: #000000;
}

.licontentPage3:active {
  color: #004cbc;
}

.blushTwo {
  position: absolute;
  width: 368px;
  height: 382px;
  margin-left: -312px;
  margin-top: 300px;
  background: linear-gradient(
    101.11deg,
    #0763b0 8.67%,
    #379ef5 89.51%,
    #379ef5 89.51%
  );
  opacity: 0.5;
  filter: blur(150px);
}

.link {
  text-decoration: none;
}

.outlet {
  /* border: 1px solid red; */
  width: 100%;
  /* height: 500px; */
  box-sizing: border-box;
  padding: 61px 76px;
}

@media screen and (max-width: 767px) {
  .contentThree {
  padding: 30px 20px;
  width: 100%;
  }


  .subTitleCT3 {
  font-size: 24px;
  line-height: 36px;
  }
  
  .contentPage3 {
  flex-direction: column;
  gap: 20px;
  }
  
  .licontentPage3 {
  font-size: 16px;
  line-height: 24px;
  }
  
  .blushTwo {
  display: none;
  }
  
  .outlet {
  padding: 30px 20px;
  }
  }

/*CONTENT 4*/

.contentFour {
  width: 80%;
  height: 225px;
  justify-content: center;
  /* margin-left: 74px; */
  position: absolute;
  background: #004cbc;
  border-radius: 40px;
  /* z-index: 1; */
  top: -150px;
  /* margin-top: 150px; */
  align-items: center;
  display: flex;
  padding: 0 50px 0 50px;
}

.contentPage4 {
  justify-content: space-between;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 57px;
  display: flex;
  align-items: center;
  color: #ffffff;
  align-items: center;
  text-align: left;
  gap: 1000px;
}

.ImageFour {
  display: flex;
  flex-direction: row;
  gap: 60px;
}

.wrapperImagePerusahaan {
  display: flex;
  flex-direction: column;
}

.wrapperImagePerusahaan:hover {
  margin-top: -30px;
  padding: 30px;
  background: #b0c8ea;
  box-shadow: 0px 4px 20px rgba(176, 200, 234, 0.5);
  border-radius: 0px 0px 20px 20px;
}

.wrapperImageKaryawan:hover {
  margin-top: -30px;
  padding: 30px;
  background: #b0c8ea;
  box-shadow: 0px 4px 20px rgba(176, 200, 234, 0.5);
  border-radius: 0px 0px 20px 20px;
  color: #004cbc;
}

.wrapperImageKaryawan {
  display: flex;
  flex-direction: column;
}

.textContent4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
}

.ImagePerusahaan {
  width: 68px;
  height: 68px;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px 20px;
}

.ImageKaryawan {
  width: 68px;
  height: 68px;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px 20px;
}

.contentFive {
  margin-top: 150px;
  width: 100%;
  height: 552px;
  background: url("../../assets/images/bgpage4.png");
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  position: relative;
}

.contentPage5 {
  width: 1057px;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* border: 1px solid black; */
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 39px;
  text-align: center;
  color: #000000;
  text-shadow: 0px -4px 10px rgba(176, 200, 234, 0.3);
}

.imgLP {
  width: 100%;
  z-index: 1;
  box-sizing: border-box;
}


@media screen and (max-width: 768px) {
  /* CONTENT 4 */
  .contentFour {
   display: none;
  }


  .contentFive {
    height: 400px;
    width: 100%;
    margin-top: -40px;
  }

  .contentPage5 {
    width: 100%;
    font-size: 22px;
    line-height: 33px;
    text-shadow: none;
    height: auto;
  }

  /* Hide elements */
  .rightContentOne {
    display: block;
  }
}

/*CONTENT SIX*/

.contentSix {
  box-sizing: border-box;
  /* height: 470px; */
  width: 100%;
  background: #fbfcff;
  padding: 48px;
  display: flex;
  flex-direction: column;
  /* border: 1px solid black; */
}

.formContentSix {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.titleContentSix {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #004cbc;
}

.subTitleContentSix {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 38px;
  line-height: 57px;
  text-align: center;
  color: #000000;
}

.carousel {
  display: flex;
  flex-direction: column;
  justify-self: center;
  width: 600px;
  height: 266px;
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(176, 200, 234, 0.3);
  border-radius: 12px;
  padding: 12px 12px;
  position: relative;
}

.petikCarousel {
  height: 85px;
  width: 100px;
  position: absolute;
}

.contentCarousel {
  padding: 77px 57px;
  box-sizing: border-box;
}

.contentTestiCarousel {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}

.contentNameCarousel {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #004cbc;
}

.contentCarousel1 {
  padding: 10px 57px;
  box-sizing: border-box;
}

.contentTestiCarousel1 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}

.contentNameCarousel1 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #004cbc;
}

#arrowLeft {
  font-size: 25px;
  /* background-color: #004cbc; */
  position: absolute;
  color: white;
  padding: 10px 13px;
  border-radius: 50%;
  top: 45%;
  left: -20px;
  background: #004cbc;
  transform: matrix(1, 0, 0, -1, 0, 0);
}

#arrowRight {
  font-size: 25px;
  /* background-color: #004cbc; */
  position: absolute;
  color: white;
  padding: 10px 13px;
  border-radius: 50%;
  top: 45%;
  right: -20px;
  background: #004cbc;
  transform: matrix(1, 0, 0, -1, 0, 0);
}

@media screen and (max-width: 768px) {
  .contentSix {
    padding: 24px;
    width: 100%;
  }
  
  .subTitleContentSix {
    font-size: 28px;
    line-height: 42px;
  }
  
  .carousel {
    width: 90%;
    height: 200px;
    padding: 12px;
  }
  
  .contentCarousel {
    padding: 36px 24px;
  }
  
  .contentTestiCarousel, .contentNameCarousel {
    font-size: 16px;
    line-height: 24px;
  }
  
  .contentCarousel1 {
    padding: 10px 24px;
  }
  
  .contentTestiCarousel1, .contentNameCarousel1 {
    font-size: 16px;
    line-height: 24px;
  }
  
  #arrowLeft, #arrowRight {
    font-size: 20px;
    padding: 8px 10px;
    top: 40%;
    
  }
}


/* CONTENT SEVEN */
.contentSeven {
  box-sizing: border-box;
  width: 100%;
  background: #ffffff;
  /* border: 1px solid black; */
  padding: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.titleContentSeven {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #737373;
}

.subTitle1ContentSeven {
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: 400;
  font-size: 80px;
  line-height: 80px;
  display: flex;
  align-items: center;
  color: #737373;
  width: 349px;
}

.subTitle2ContentSeven {
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: 400;
  font-size: 80px;
  line-height: 80px;
  display: flex;
  align-items: center;
  color: #004CBC;
  width: 349px;
}

.botTitleContentSeven {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #004CBC;
}

.teamContentSeven {
  display: flex;
  flex-direction: column;
}

.teamOneContentSeven {
  display: flex;
  justify-content: center;
}

.teamtwoContentSeven {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 5px;
}

.cardContentSevenGrey {
  display: flex;
  position: relative;
  width: 115px;
  height: 115px;
  background: #D6D6D6;
}

.cardContentSevenAbuMuda {
  display: flex;
  position: relative;
  width: 115px;
  height: 115px;
  background: #e8e8e8;
}

@media screen and (max-width: 768px) {
  .contentSeven {
    flex-direction: column;
    padding: 24px;
  }

  .subTitle1ContentSeven,
  .subTitle2ContentSeven {
    font-size: 60px;
    line-height: 60px;
    width: auto;
    margin-bottom: 10px;
  }

  .botTitleContentSeven{
    margin-bottom: 10px;
  }

  .teamOneContentSeven {
    flex-wrap: wrap;
    justify-content: center;
  }

  .cardContentSevenGrey,
  .cardContentSevenAbuMuda {
    width: calc(39.33% - 20px);
  }
}


/*CONTENT EIGHT*/

.contentEight {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* height: 685px; */
  background: #ffffff;
  /* border: 1px solid black; */
}

.formContentEight {
  display: flex;
  flex-direction: column;
  padding: 48px 183px;
  box-sizing: content-box;
}

.titleContentEight {
  /* align-items: center; */
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #004cbc;
}

.subTitleContentEight {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  line-height: 57px;
  /* display: flex; */
  color: #000000;
  text-align: center;
  /* align-items: center; */
}

.faq {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 80px; */
  padding: 25px 36px;
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(176, 200, 234, 0.3);
  border-radius: 12px;
  /* justify-content: space-between; */
  /* justify-content: center;
    align-items: center; */
}
.faq.showFaq1Active {
  border-left: 3px solid #004cbc;
}

.question {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.answer {
  margin-top: 20px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #999999;
}

.spanfaq {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  align-items: center;
}

#iconplus {
  font-size: 36px;
  color: #c4c4c4;
  cursor: pointer;
}
#iconminus {
  font-size: 36px;
  color: #004cbc;
  cursor: pointer;
}
#iconplus:hover {
  color: #004cbc;
}

@media screen and (max-width: 768px) {
  .formContentEight {
    padding: 48px;
    justify-content: center;
  }

  .subTitleContentEight {
    font-size: 28px;
    line-height: 42px;
  }

  .faq {
    padding: 25px;
    display: flex;
    justify-content: center;
    width: 85%;
  }

  .answer {
    font-size: 16px;
    line-height: 24px;
  }

  #iconplus,
  #iconminus {
    font-size: 24px;
  }
}



/*NINE*/
.contentNine {
  width: 100%;
  background: #ffffff;
  /* height: 850px; */
  /* height: 950px; */
}

.containerContentNine {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 48px 70px;
  box-sizing: content-box;
}

.leftContainerNine {
  width: 495px;
  height: 635px;
  background: #e6edf8;
  border-radius: 20px;
  /* margin-top: 80px; */
  padding: 60px 40px;
  box-sizing: border-box;
}

.h3containerNine {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #000000;
}
.spancontainerNine {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  color: #000000;
}

.judul {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  padding: 0px;
  /* border: 1px solid black; */
}

.span {
  width: 357px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #737373;
}

.spanLokasi{
  width: 357px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #737373;
}

.contentLeftContainerNine {
  gap: 30px;
}

.contentContainerNine {
  display: flex;
  flex-direction: row;
  gap: 20px;
  /* display: inline-flex; */
}

.leftIMG {
  width: 40px;
  height: 40px;
  top: 0;
  left: 0;
  background: #b0c8ea;
  border-radius: 100px;
}

.imgContainerNine {
  align-items: center;
  margin-top: 10px;
  margin-left: 10px;
  height: 19.48px;
  width: 19.48px;
}
.textContainerNine {
  display: flex;
  flex-direction: column;
}

.formContainerNine {
  display: flex;
  flex-direction: column;
}

.formTopNine {
  display: flex;
  flex-direction: row;
}

.formNENine {
  display: flex;
  flex-direction: column;
}
.labelContentNine {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.inputNE {
  width: 320px;
  height: 56px;
  background: #ffffff;
  border: 1px solid #b0c8ea;
  border-radius: 12px;
}

.inputSubjek {
  width: 660px;
  height: 56px;
  background: #ffffff;
  border: 1px solid #b0c8ea;
  border-radius: 12px;
}

.inputSubjek {
  width: 660px;
  height: 56px;
  background: #ffffff;
  border: 1px solid #b0c8ea;
  border-radius: 12px;
}

.inputPesan {
  width: 660px;
  height: 290px;
  background: #ffffff;
  border: 1px solid #b0c8ea;
  border-radius: 12px;
}

.btnNine {
  width: 228px;
  height: 48px;
  background: #c4c4c4;
  border-radius: 12px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

@media screen and (max-width: 768px) {
  .containerContentNine {
    flex-direction: column;
    padding: 24px;
  }
  
  .leftContainerNine {
    width: 100%;
    height: auto;
    margin-top: 24px;
    padding: 24px;
  }

  .rightContainerNine{
    margin-top: 40px;
    width: 100%;
  }
  
  .contentContainerNine {
    flex-direction: column;
    gap: 16px;
  }
  
  .inputNE, .inputSubjek, .inputPesan {
    width: 100%;
  }
  
  .btnNine {
    width: 100%;
    margin-top: 16px;
  }
  .span{
  /* display: flex;
  justify-content: center;
  align-items: center; */
  width: 90%;
  }

  .inputSubjek{
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .inputPesan{
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .btnNine{
    /* display: flex;
    justify-content: center; */
    width: 100%;
  }
}


.contentSelingan {
  width: 1218px;
  height: 180px;
  /* margin-left: 74px; */
  position: absolute;
  top: -100px;
  align-items: center;
  text-align: center;
  /* z-index: 1; */
  justify-content: center;
  display: flex;
  background: #ffffff;
  box-shadow: 0px 0px 40px rgba(176, 200, 234, 0.4);
  border-radius: 30px;
}

.isiContentSelingan {
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.h3Selingan {
  width: 621px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  display: flex;
  align-items: center;
  color: #004cbc;
  padding: 45px;
}

.btnSelingan {
  width: 190px;
  height: 50px;
  background: #004cbc;
  box-shadow: 6px 4px 25px rgba(0, 76, 188, 0.3);
  border-radius: 12px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .contentSelingan {
    /* width: 100%;
    height: auto;
    top: 0;
    border-radius: 0;
    justify-content: center; */
    display: none;
  }
  
  /* .isiContentSelingan {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  
  .h3Selingan {
    width: 100%;
    font-size: 24px;
    line-height: 36px;
    padding: 24px;
    justify-content: center;
    text-align: center;
  }
  
  .btnSelingan {
    margin-top: 24px;
    width: 150px;
    height: 40px;
    font-size: 14px;
    border-radius: 8px;
    display: none;
  } */
}


/* .emailContainerNine{
    display: flex;
    flex-direction: row;
}
.textEmailContainerNine{
    display: flex;
    flex-direction: column;
} */

/*TEN*/

.contentTen {
  width: 100%;
  /* height: 428px; */
  background: linear-gradient(
    101.11deg,
    #0763b0 8.67%,
    #379ef5 89.51%,
    #379ef5 89.51%
  );
  display: flex;
  position: relative;
  margin-top: 130px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.contentWrapperTen {
  display: flex;
  flex-direction: row;
  padding: 111px 80px;
  box-sizing: content-box;
}

.contentFirst {
  display: flex;
  flex-direction: column;
  /* padding: 111px 80px;
    box-sizing: content-box; */
}

.firstTextContent10 {
  width: 282px;
}
.firstSpanContent10 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}

.SpanContent10 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}

.boldSpanContent10 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}

/* .contentSecond {
    display: flex;
    flex-direction: column;
    padding: 111px 80px;
    box-sizing: content-box;
} */

.spanTitleContentSecond {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.liContentSecond {
font-family: "Poppins", sans-serif;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 30px;
color: #ffffff;
border: none;
}

.linkContentSecond {
text-decoration: none;
border: none;
}

/* .contentThird {
    display: flex;
    flex-direction: column;
    padding: 111px 80px;
    box-sizing: content-box;
} */

.spanTitleContentThird {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.liContentThird {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #ffffff;
}

.spanTitleContentFourth {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.spanSubTitleContentFourth {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: justify;
  color: #ffffff;
  width: 334px;
}

.inputContentFourth {
  display: flex;
  flex-direction: row;
}

.ipContentFourth {
  width: 214px;
  height: 44px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #c4c4c4;
  border-radius: 8px 0px 0px 8px;
  padding: 0px 10px;
}

.buttonContentFourth {
  width: 110px;
  height: 44px;
  background: #004cbc;
  border-radius: 0px 8px 8px 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
}

/* Media Query untuk layar dengan lebar 768px atau lebih kecil */
@media (max-width: 768px) {
  .contentTen{
    width: 100%;
    margin-top: 10px;
  }
  .contentWrapperTen {
    flex-direction: column;
    /* margin-top: 20px; */
    width: 100%;
    padding: 20px 0 20px 0;
  }
  
  .contentFirst {
    margin-bottom: 50px;
  }
  
  .spanTitleContentSecond,
  .spanTitleContentThird,
  .spanTitleContentFourth {
    margin-top: 50px;
  }
  
  .ipContentFourth {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .buttonContentFourth {
    width: 100%;
    border-radius: 8px;
  }

  .inputContentFourth {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .contentFourth {
    padding: 0 20px;
}

.firstSpanContent10 { 
  text-align: center;
  padding: 0 20px;
}

.firstTextContent10 {
  width: 100%;
  text-align: center;
}

.spanSubTitleContentFourth{
  text-align: center;
}
}


.footer {
  height: 90px;
  background: #e7f1fd;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.footerAtas {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #000000;
}

.footerBawah {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #000000;
}

.footerBold {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #000000;
}

@media screen and (max-width: 768px) {
  .footer {
    height: auto;
    padding: 30px 0;
  }

  .footerAtas,
  .footerBawah,
  .footerBold {
    font-size: 12px;
    line-height: 16px;
  }
}


.videoCamera{
  align-items: center;
  justify-content: center;
}

/* @media screen and (min-width: 768px) {
  .rightContentOne {
    display: none !important;
  }
  .leftContentOne{
    display: flex;
    flex-direction: column;
  } 

  .leftTitleContentOne {
    margin-left: 0;
  }
  
  .titleBergerak {
    position: relative;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 57px;
    color: #0763b0;
  }
  
  .titleContentOne {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 47px;
    color: #000000;
    position: relative;
  }
  
  .titleBergerak:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #ffffff;
    animation: animate 4s steps(16) infinite;
  }
  
  @keyframes animate {
    40%,
    60% {
      left: 100%;
    }
    100% {
      left: 0%;
    }
  }
  
  .leftSubTitleContentOne {
    width: 537px;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 57px;
    color: #000000;
    margin-left: 108px;
    padding: 0 20px;
  } */




/* @media screen and (min-width: 768px) {
  .rightContentOne {
    display: none;
}
.leftContentOne{
  display: flex;
  flex-direction: column;
} 

.leftTitleContentOne {
  margin-left: 0;
}

.titleBergerak {
  position: relative;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 57px;
  color: #0763b0;
}

.titleContentOne {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 47px;
  color: #000000;
  position: relative;
}

.titleBergerak:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  animation: animate 4s steps(16) infinite;
}

@keyframes animate {
  40%,
  60% {
    left: 100%;
  }
  100% {
    left: 0%;
  }
}

.leftSubTitleContentOne {
  width: 537px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 57px;
  color: #000000;
  margin-left: 108px;
  padding: 0 20px;
}
} */


@media screen and (max-width: 768px) {
  .contentTwo {
    flex-direction: column;
    height: auto;
    padding: 60px 0;
    gap: 30px;
    width: 100%;
  }

  .laptop {
    display: none;
  }
  
  .imgHP {
    position: relative;
    margin-left: 0;
    margin-top: 0;
    width: 100%;
    max-width: 360px;
    height: auto;
    
  }

  .imageGroup {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 360px;
    height: auto;
  }

  .rightContentTwo {
    margin-top: 30px;
    gap: 15px;
  }

  .rightContentTwoH3 {
    width: 100%;
    font-size: 28px;
    line-height: 40px;
    text-align: center;
    margin-bottom: 15px;
  }

  .rightContentTwoP {
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    text-align: justify;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


