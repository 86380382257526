*{
    user-select: none;
}

.wrapperPermission {
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    /* overflow: auto;     */
    z-index: 100;
}

.wrapperBoxPermission {
display: flex;
flex-direction: column;
align-items: center;
background-color: #fff; 
width: 650px;
height: 550px;
margin: 200px auto;
border-radius: 20px;
z-index: 2;
}

.wrapperTitlePermisson{
padding: 20px;
display: flex;
/* justify-content: center; */
align-items: center;
}



.buttonPermission{
    /* width: 400px;
    height: 65px; */
    padding: 20px 130px;
    margin: auto;
    background: #004CBC;
    border-radius: 20px;
    font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 30px;
text-align: center;
color: #FFFFFF;
}

.h3titlePermisson{
font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 36px;
text-align: center;
color: #000000;
}

.ptitlePermisson{
font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 30px;
text-align: center;
color: #000000;
}

.img-gray {
    filter: grayscale(100%);
}