@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

* {
  border: none;
}

.option-register-wrapper {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  overflow: auto;
  display: flex;
  place-content: center;
  align-content: center;
  padding: 35px 0;
  height: 100%;
  margin-bottom: 20px;
  z-index: 3;
}

.option-register-box {
  /* background-color: #fff; */
  /* width: 600px; */
  /* margin: 160px auto; */
  border-radius: 5px;
  display: grid;
  grid-template-areas: "lpb-left lpb-right";
  grid-template-columns: 1fr 1fr;
  box-shadow: 10px 10px 50px rgba(0, 76, 140, 0.1);
}

.option-register-wrapper .option-register-wrapper-left {
  grid-area: lpb-left;
  /* display: block; */
  /* flex-basis: 30%; */
  position: relative;
  /* border: 1px solid black; */
}
.option-register-wrapper .option-register-wrapper-left .option-register-desc {
  position: absolute;
  top: 0;
  /* border: 5px solid black; */
  padding: 0px 40px;
}

.option-register-wrapper-left img {
  flex-basis: 100%;
  width: 400px;
  height: 600px;
}

.option-register-wrapper-left h2 {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 600px;
  line-height: 30px;
  text-align: center;
  margin-top: 100px;
  text-align: start;
}

.option-register-wrapper-left p {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 500px;
  line-height: 20px;
  font-style: italic;
}

.option-register-box .option-register-wrapper-right {
  grid-area: lpb-right;
  padding: 0px;
  background-color: rgb(255, 255, 255);
  /* flex-basis: 70%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  height: 600px;
}

.option-register-img-wrapper {
  display: flex;
  flex-direction: row;
}

.option-register-wrapper-right .option-register-img-wrapper .imageAdmin {
  width: 170px;
  height: 150px;
  border: 1px solid blue;
  border-radius: 15px;
  margin: 10px;
}

.option-register-wrapper-right .button-option {
  width: 100%;
  height: 50px;
  font-size: 20px;
  color: white;
  background-color: #c4c4c4;
  padding: 12px;
  border-radius: 12px;
  font-weight: 400;
  border: none;
}

.option-register-box .option-register-wrapper-right h1 {
  margin: 0;
  font-size: 35px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  text-align: center;
}

.imageAdmin {
  cursor: pointer;
}

.option-register-wrapper-right .wrapper-close {
  position: relative;
}

.buttonCloseRegister{
  position: absolute;
  top: -150px;
  right: -160px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .option-register-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .option-register-box {
    display: flex;
    flex-direction: column;
    mix-blend-mode: normal;
    /* overflow: scroll; */
    box-shadow: none;
  }

  .option-register-wrapper .option-register-wrapper-right {
    margin-top: -45px !important;
    border-radius: 50px 0 0 0;
    position: relative;
    height: 100% !important;
    flex-direction: column;
  }

  .option-register-img-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .option-register-wrapper .option-register-wrapper-left img {
    height: 360px;
    width: 100%;
    top: 0;
    margin-top: -195px;
    border-radius: 0px;
    /* position: fixed; */
  }
  .option-register-wrapper-left h2 {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 25px;
    font-weight: 600px;
    line-height: 30px;
    text-align: start;
    margin-top: -40px;
  }

  .option-register-wrapper-left p {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-weight: 500px;
    line-height: 20px;
    font-style: italic;
  }

  .option-register-wrapper-left .option-register-desc {
    margin-top: auto;
  }

  /* .option-register-box .option-register-wrapper-right .form-login-pwa-wrapper h1 {
    margin: 0;
    font-size: 25px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    text-align: center;
  }
  .login-pwa-wrapper
    .login-pwa-box
    .login-pwa-wrapper-right
    .form-login-pwa-wrapper
    form
    .input-password
    #mata {
    position: absolute;
    left: 0;
    top: 0;
    margin-left: 260px;
    line-height: 50px;
    color: #c4c4c4;
  } */

  /* .login-pwa-box .form-login-pwa-wrapper {
    width: 80%;
    margin-top: 30px;
  } */
  .option-register-box .option-register-wrapper-right {
    grid-area: lpb-right;
    padding: 0px;
    /* background-color: rgb(255, 255, 255); */
    /* flex-basis: 70%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -25px;
    /* position: fixed; */
    /* border: 1px solid black; */
  }

  .option-register-wrapper-right .wrapper-close {
    position: relative;
  }
  
  .buttonCloseRegister{
    position: absolute;
    top: 30px;
    right: -180px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    font-size: 20px;
  }
}