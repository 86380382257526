*{
    user-select: none;
}

.modal-face-wrapper {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: auto;    
    z-index: 100;
}

.modal-face-box {
display: flex;
flex-direction: column;
align-items: center;
background-color: #fff; 
width: 550px;
height: 250px;
margin: 200px auto;
border-radius: 20px;
z-index: 2;
}

.modal-face-box-header{
padding: 10px;
display: flex;
/* justify-content: center; */

}

/* .modal-box-tittle {
  justify-content: center;  
} */

/* .modal-face-content {
    padding: 10px;
}
.modal-face-footer {
    padding: 10px;
    background-color: ;
    border-radius: 20px;
} */

.modal-face-footer-active {
    padding: 10px;
    background-color: blue
}

.face {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.button-face {
    padding: 15px;
    width: 100%;
    margin: auto;
    border-radius: 20px;
    font-size: 20px;
    color: white;
    background-color: #004CBC;
}

.button-face-active {
    width: 100%;
    margin: auto;
    background-color: blue;
    cursor: pointer;
}
 h3{
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 30px;
    color: black;
  }
.img-gray {
    filter: grayscale(100%);
}