@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* align-items: center; */
  /* z-index: 1; */
  /* top: 0; */
  /* border: 3px solid black; */
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 50px;
  height: 80px;
  background-color: #fff;
  width: 100%;
  box-shadow: 0px 4px 30px rgba(176, 200, 234, 0.3);
  background: #ffffff;
  position: fixed;
  z-index: 2;
  box-sizing: border-box;
}

.leftNavbar {
  display: flex;
  align-items: center;
}

.logo {
  width: 200px;
  height: 57px;
}

.centerNavbar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentLI {
  margin: 0 10px;
  list-style: none;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

.rightNavbar {
  display: flex;
  align-items: center;
}

.bttnLoginNavbar {
  background: #ffffff;
  border: 1px solid #e6edf8;
  border-radius: 8px;
  padding: 10px 20px;
  margin-right: 10px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  align-items: center;
  text-align: center;
  line-height: 21px;
  color: #000000;
  cursor: pointer;
}

.bttnSignupNavbar {
  background: #004cbc;
  border-radius: 8px;
  padding: 10px 20px;
  color: white;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  align-items: center;
  text-align: center;
  line-height: 21px;
  cursor: pointer;
}

.LinkLI {
  text-decoration: none;
  color: #333;
}

.navToggle {
  display: none;
}

@media screen and (max-width: 768px) {
.navbar {
  display: flex;
  justify-content: center;
}
  .centerNavbar {
    display: none;
  }

  .logo {
    width: 100px;
    height: 40px;
    margin-right: 10px;
  }

  .navToggle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .navOpen {
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease-in-out;
    transform: translateX(0%);
  }

  .contentLI {
    margin: 10px 0;
    font-size: 1.2rem;
    text-align: center;
  }

  .navOpen .contentLI {
    opacity: 0;
    animation: fadeIn 0.5s ease forwards;
    animation-delay: 0.3s;
  }

  .navOpen .contentLI:nth-child(1) {
    animation-delay: 0.4s;
  }

  .navOpen .contentLI:nth-child(2) {
    animation-delay: 0.5s;
  }

  .navOpen .contentLI:nth-child(3) {
    animation-delay: 0.6s;
  }

  .navOpen .contentLI:nth-child(4) {
    animation-delay: 0.7s;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }
}

/*CONTENT PRICING*/

.contentPricing {
  width: 100%;
  margin-top: 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* border: 1px solid black; */
  box-sizing: border-box;
}

.formContentPrice {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.titlePricing {
  align-items: center;
}

.pTitlePricing {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 57px;
  text-align: center;
  color: #000000;
}

.spanTitlePricing {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 57px;
  text-align: center;
  background: linear-gradient(
    101.11deg,
    #0763b0 8.67%,
    #379ef5 89.51%,
    #379ef5 89.51%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.listPricing {
  display: flex;
  justify-content: center;
  /* flex-direction: row; */
  /* justify-content: center; */
  overflow-x: hidden;
  width: 100%;
  /* padding: 10px; */
  /* border: 1px solid black; */
  box-sizing: border-box;
}

.itemPricing {
  box-sizing: border-box;
  width: 290px;
  /* height: 580px; */
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(176, 200, 234, 0.3);
  border-radius: 20px;
  text-align: left;
  padding: 33px 31px;
}

.topItemPricing {
  width: 70%;
  /* border-bottom: 1px solid #b0c8ea; */
}

.h3ItemPricing {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #000000;
}

.spanItemPricing {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #004cbc;
  /* margin-bottom: 40px; */
}

.pItemPricing {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #c4c4c4;
  width: 200px;
  margin-top: 15px;
}

.pItem2Pricing {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #c4c4c4;
  width: 221px;
}

.liItemPricing {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #000000;
}

.divItemPricing {
  display: flex;
  align-items: center;
}
.btnPricing {
  width: 228px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #004cbc;
  border-radius: 12px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #004cbc;
}

#checklist {
  color: #1277ea;
  /* background: #d9e4f5; */
  /* width: 10px;
  height: 10px;
  border-radius: 50%; */
}

#silang {
  color: #ed7711;
}

.border {
  border: 1px solid #b0c8ea;
  margin-top: 3px;
}

.borderUnlimited {
  border: 1px solid #b0c8ea;
}
.LinkLI {
  text-decoration: none;
}

@media (max-width: 768px) {
  .contentPricing{
    width: 100%;
  }
  .formContentPrice{
    display: flex;
    flex-direction: column;
  }
  .listPricing{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .itemPricing{
    margin-top: 10px;
  }
}

/*CONTENT TEN*/

.contentTen {
  width: 100%;
  /* height: 428px; */
  background: linear-gradient(
    101.11deg,
    #0763b0 8.67%,
    #379ef5 89.51%,
    #379ef5 89.51%
  );
  display: flex;
  position: relative;
  margin-top: 130px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.contentWrapperTen {
  display: flex;
  flex-direction: row;
  padding: 111px 80px;
  box-sizing: content-box;
}

.contentFirst {
  display: flex;
  flex-direction: column;
  /* padding: 111px 80px;
    box-sizing: content-box; */
}

.firstTextContent10 {
  width: 282px;
}
.firstSpanContent10 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}

.SpanContent10 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}

.boldSpanContent10 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}

/* .contentSecond {
    display: flex;
    flex-direction: column;
    padding: 111px 80px;
    box-sizing: content-box;
} */

.spanTitleContentSecond {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.liContentSecond {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #ffffff;
}

/* .contentThird {
    display: flex;
    flex-direction: column;
    padding: 111px 80px;
    box-sizing: content-box;
} */

.spanTitleContentThird {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.liContentThird {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #ffffff;
}

.spanTitleContentFourth {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.spanSubTitleContentFourth {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: justify;
  color: #ffffff;
  width: 334px;
}

.inputContentFourth {
  display: flex;
  flex-direction: row;
}

.ipContentFourth {
  width: 214px;
  height: 44px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #c4c4c4;
  border-radius: 8px 0px 0px 8px;
  padding: 0px 10px;
}

.buttonContentFourth {
  width: 110px;
  height: 44px;
  background: #004cbc;
  border-radius: 0px 8px 8px 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
}




@media (max-width: 768px) {
  .contentTen{
    width: 100%;
    margin-top: 20px;
  }
  .contentWrapperTen {
    flex-direction: column;
    /* margin-top: 20px; */
    width: 100%;
    padding: 20px 0 20px 0;
  }
  
  .contentFirst {
    margin-bottom: 50px;
  }
  
  .spanTitleContentSecond,
  .spanTitleContentThird,
  .spanTitleContentFourth {
    margin-top: 50px;
  }
  
  .ipContentFourth {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .buttonContentFourth {
    width: 100%;
    border-radius: 8px;
  }

  .inputContentFourth {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .contentFourth {
    padding: 0 20px;
}

.firstSpanContent10 { 
  text-align: center;
  padding: 0 20px;
}

.firstTextContent10 {
  width: 100%;
  text-align: center;
}

.spanSubTitleContentFourth{
  text-align: center;
}
}


.footer {
  height: 90px;
  background: #e7f1fd;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.footerAtas {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #000000;
}

.footerBawah {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #000000;
}

.footerBold {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #000000;
}

@media screen and (max-width: 768px) {
  .footer {
    height: auto;
    padding: 30px 0;
  }

  .footerAtas,
  .footerBawah,
  .footerBold {
    font-size: 12px;
    line-height: 16px;
  }
}