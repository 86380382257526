@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* align-items: center; */
  /* z-index: 1; */
  /* top: 0; */
  /* border: 3px solid black; */
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 50px;
  height: 80px;
  background-color: #fff;
  width: 100%;
  box-shadow: 0px 4px 30px rgba(176, 200, 234, 0.3);
  background: #ffffff;
  position: fixed;
  z-index: 2;
  box-sizing: border-box;
}

.leftNavbar {
  display: flex;
  align-items: center;
}

.logo {
  width: 200px;
  height: 57px;
}

.centerNavbar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentLI {
  margin: 0 10px;
  list-style: none;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

.rightNavbar {
  display: flex;
  align-items: center;
}

.bttnLoginNavbar {
  background: #ffffff;
  border: 1px solid #e6edf8;
  border-radius: 8px;
  padding: 10px 20px;
  margin-right: 10px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  align-items: center;
  text-align: center;
  line-height: 21px;
  color: #000000;
  cursor: pointer;
}

.bttnSignupNavbar {
  background: #004cbc;
  border-radius: 8px;
  padding: 10px 20px;
  color: white;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  align-items: center;
  text-align: center;
  line-height: 21px;
  cursor: pointer;
}

.LinkLI {
  text-decoration: none;
  color: #333;
}

.navToggle {
  display: none;
}

@media screen and (max-width: 768px) {
.navbar {
  display: flex;
  justify-content: center;
}
  .centerNavbar {
    display: none;
  }

  .logo {
    width: 100px;
    height: 40px;
    margin-right: 10px;
  }

  .navToggle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .navOpen {
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease-in-out;
    transform: translateX(0%);
  }

  .contentLI {
    margin: 10px 0;
    font-size: 1.2rem;
    text-align: center;
  }

  .navOpen .contentLI {
    opacity: 0;
    animation: fadeIn 0.5s ease forwards;
    animation-delay: 0.3s;
  }

  .navOpen .contentLI:nth-child(1) {
    animation-delay: 0.4s;
  }

  .navOpen .contentLI:nth-child(2) {
    animation-delay: 0.5s;
  }

  .navOpen .contentLI:nth-child(3) {
    animation-delay: 0.6s;
  }

  .navOpen .contentLI:nth-child(4) {
    animation-delay: 0.7s;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }
}
/*CONTENT PRICING*/

.LinkLI {
  text-decoration: none;
}

.wrapperTitleStructure{
position: absolute;
width: 338px;
height: 174px;
left: 248px;
top: 152px;
text-align: left;
}

.wrapperPTitleStructure{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 58px;
  color: #000000;
}
.wrapperSpanTitleStructure{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 58px;
  background: linear-gradient(101.11deg, #0763B0 8.67%, #379EF5 89.51%, #379EF5 89.51%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
}

.wrapperTeamStructure{
  width: 100%;
  /* height: 1000px; */
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  text-align: center;
  /* border: 1px solid black; */
}

.boxTeamStructure {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  width: 100%;
}

.nameTeamStructure{
  display: flex;
  justify-content: center;
align-items: center;
  font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 15px;
line-height: 21px;
text-align: center;
color: #000000;
}

.positionTeamStructure{
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 18px;
text-align: center;
color: #737373;
}

.timNgabsign{
  width: 190.83px;
height: 140.76px;
}

.sosmedTeam{
  display: flex;
  justify-content: center;
  flex-direction: row;
}

#instagram {
  filter: grayscale(100%);
}
#instagram:hover {
  filter: grayscale(0%);
}

#linkedin {
  filter: grayscale(100%);
}
#linkedin:hover {
  filter: grayscale(0%);
}

/* .TeamStructureOne {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.TeamStructureTwo{
  display: flex;
  flex-direction: column;
  justify-content: center;

} */
.TeamStructureThree{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.TeamStructureFour{
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: start;
 margin-left: -220px;
}
.TeamStructureFive{
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
 margin-left: -420px;

}
.TeamStructureSix{
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
 margin-left: 0px;

}

.cardContentSevenGrey {
  display: flex;
  width: 150px;
height: 150px;
background: #E8E8E8;
border-radius: 8px;
flex: none;
order: 0;
flex-grow: 0;
}


.wrapperCardContentSevenGrey{
  display: flex;
flex-direction: column;
align-items: center;
padding: 8px 6px 87px;
gap: 10px;
/* position: absolute; */
width: 150px;
height: 180px;
left: calc(50% - 150px/2);
top: 124px;
background: #FFFFFF;
border-bottom: 4px solid #004CBC;
box-shadow: 0px 2px 10px rgba(0, 76, 188, 0.1);
border-radius: 12px;
}

.wrapperCardContentSevenMobile{
  display: flex;
flex-direction: column;
align-items: center;
padding: 8px 6px 87px;
gap: 10px;
/* position: absolute; */
width: 150px;
height: 180px;
left: calc(50% - 150px/2);
top: 124px;
background: #FFFFFF;
border-bottom: 4px solid #0DB73C;
box-shadow: 0px 2px 10px rgba(0, 76, 188, 0.1);
border-radius: 12px;
}
.wrapperCardContentSevenGBA{
  display: flex;
flex-direction: column;
align-items: center;
padding: 8px 6px 87px;
gap: 10px;
/* position: absolute; */
width: 150px;
height: 180px;
left: calc(50% - 150px/2);
top: 124px;
background: #FFFFFF;
border-bottom: 4px solid #ED7711;
box-shadow: 0px 2px 10px rgba(0, 76, 188, 0.1);
border-radius: 12px;
}
.wrapperCardContentSevenWeb{
  display: flex;
flex-direction: column;
align-items: center;
padding: 8px 6px 87px;
gap: 10px;
/* position: absolute; */
width: 150px;
height: 180px;
left: calc(50% - 150px/2);
top: 124px;
background: #FFFFFF;
border-bottom: 4px solid #790CCE;
box-shadow: 0px 2px 10px rgba(0, 76, 188, 0.1);
border-radius: 12px;
}
.wrapperCardContentSevenUIUX{
  display: flex;
flex-direction: column;
align-items: center;
padding: 8px 6px 87px;
gap: 10px;
/* position: absolute; */
width: 150px;
height: 180px;
left: calc(50% - 150px/2);
top: 124px;
background: #FFFFFF;
border-bottom: 4px solid #D12035;
box-shadow: 0px 2px 10px rgba(0, 76, 188, 0.1);
border-radius: 12px;
}
.wrapperCardContentSevenJA{
  display: flex;
flex-direction: column;
align-items: center;
padding: 8px 6px 87px;
gap: 10px;
/* position: absolute; */
width: 150px;
height: 180px;
left: calc(50% - 150px/2);
top: 124px;
background: #FFFFFF;
border-bottom: 4px solid #0BA236;
box-shadow: 0px 2px 10px rgba(0, 76, 188, 0.1);
border-radius: 12px;
}

/*  */

.test {
  position: relative;
}
.test2{
  position: absolute; 
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);
}
.test3{
  width: 2px; 
  height: 41px; 
  background-color: black;
   margin: 0;
}

.garis1{
  width:80% ;
}
.garis2{
  margin-left:400px ;
}
.garislurus1{
  margin-top: 10px;
}
.garislurus2{
  margin-top: 26px;
}
.garislurus3{
  margin-top: 26px;
}

@media screen and (max-width: 1280px) {
  .garis1{
    width:120% ;
  }
}

@media screen and (max-width: 1024px) {
  .garis1{
    width:100% ;
  }
}

/* @media screen and (max-width: 768px) {
  .garis1 {
    display: none;
  }
  .garis2 {
    display: none;
  }
  .garislurus1 {
    display: none;
  }
  .garislurus2 {
    display: none;
  }
  .garislurus3 {
    display: none;
  }
  
  .wrapperTeamStructure {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    justify-items: center;
    align-items: center;
    text-align: center;
  }

  .TeamStructureOne,
  .TeamStructureTwo,
  .TeamStructureThree,
  .TeamStructureFour,
  .TeamStructureFive,
  .TeamStructureSix {
    width: 100%;
  }

  .TeamStructureFour {
    grid-column: 2;
  }

  .TeamStructureFive {
    grid-column: 3;
  }

  .TeamStructureSix {
    grid-column: 4;
  }
} */

@media screen and (max-width: 768px) {
  .garis1 {
    display: none;
  }
  .garis2 {
    display: none;
  }
  .garislurus1 {
    display: none;
  }
  .garislurus2 {
    display: none;
  }
  .garislurus3 {
    display: none;
  }
  
  .wrapperTeamStructure {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .TeamStructureOne,
  .TeamStructureTwo,
  .TeamStructureThree,
  .TeamStructureFour,
  .TeamStructureFive,
  .TeamStructureSix {
    width: 100%;
  }

  .TeamStructureFour {
    order: 2;
  }

  .TeamStructureFive {
    order: 3;
  }

  .TeamStructureSix {
    order: 4;
  }
}

@media screen and (max-width: 768px) {
  .wrapperTeamStructure {
    flex-wrap: wrap;
  }

  .column {
    width: 50%;
  }
}






/* @media screen and (max-width: 768px) {
  .garis1{
    display: none;
  }
  .garis2{
    display: none;
  }
  .garislurus1{
    display: none;
  }
  .garislurus2{
    display: none;
  }
  .garislurus3{
    display: none;
  }
  .TeamStructureOne {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.TeamStructureTwo{
  display: flex;
  flex-direction: column;

}
.TeamStructureThree{
  display: flex;
  flex-direction: column;
}
.TeamStructureFour{
  display: flex;
  flex-direction: column;
}
.TeamStructureFive{
  display: flex;
  flex-direction: column;
}
.TeamStructureSix{
  display: flex;
  flex-direction: column;
}
.wrapperTeamStructure {
  flex-wrap: wrap;
  justify-content: flex-start;
}

.TeamStructureOne,
.TeamStructureTwo,
.TeamStructureThree,
.TeamStructureFour,
.TeamStructureFive,
.TeamStructureSix {
  width: 50%;
  flex-grow: 1;
}

.TeamStructureFour,
.TeamStructureFive,
.TeamStructureSix {
  order: -1;
}

} */
