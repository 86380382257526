.wrapper {
  /* background-color: #f3f4f5; */
  height: 100%;
}

/* .notification {
  position: relative;
  z-index: 999;
} */

.main {
  display: flex;
  overflow: auto;
  height: 100%;
}

.content {
  margin-left: 250px !important;
  margin-top: 46px !important;
  flex: 1;
  height: 100%;
  /* position: fixed;
   */
  /* margin: 24px 48px 24px 24px; */
  /* min-height: calc(100vh - 70px); */
}

@media screen and (max-width: 1024px) {
  .content {
    margin-left: 100px !important;
  }
}

@media screen and (max-width: 768px) {
  .content {
    margin-left: 0 !important;
    margin-top: 0 !important;
    z-index: 4;
  }
}
