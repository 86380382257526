.pageTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: black;
  font-family: 'Poppins', sans-serif;
  margin-left: 50px;
}

.detailContainer {
  margin-top: 24px;
  display: flex;
  flex-direction: column;

}


/* main */
.container {
  display: flex;
}
.leftContainerWrapper{
  width: 100%;
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  border-right: 2px solid #f3f4f5;
  height: 720px;
}

.leftContainer {
  width: 100%;
  display: flex;
  flex-direction: row;

}

h4 {
font-size: 16px;
line-height: 24px;
font-weight: 400;
}

/* main detail */

/* Perizinan Fullname */
.perizinanFullname {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.inputFullname  {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  width: 100%;
  color: black;
}

/* Perizinan Departemen */
.perizinanDepartemen {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.inputDepartemen  {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  width: 100%;
  color: black;
}

/* Tanggal Clock In */
.tanggalClockIn {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.inputTanggalClockIn   {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  width: 100%;
  color: black;
}

/* Jam Masuk */
.jamMasuk{
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.inputJamMasuk  {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  width: 100%;
  color: black;
}
.inputStatus{
  padding: 2px 2px;
  background: #D9E4F5;
border-radius: 6px;
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
display: flex;
align-items: center;
text-align: center;
color: #000000;
}
label {
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
font-family: 'Poppins', sans-serif;
color: #B0B0B0;
}

.wrapperLokasi{
  display: flex;
  flex-direction: column;
}

.titleLokasi {
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
color: #B0B0B0;
margin-left: 50px;
}

.subTitleLocakasi{
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 27px;
color: #000000;
width: 80%;
margin-left: 50px;
}

.wrapperbtnClockIn{
  display: flex;
  flex-direction: row;
}

.btnBackClockIn {
  background: #FFFFFF;
border: 1px solid #004CBC;
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
text-align: center;
color: #000000;
margin-left: 50px;
padding: 12px 45px;
border-radius: 12px;
}

.btnClockIn {
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
padding: 12px 45px;
background: #004CBC;
border-radius: 12px;
color: white;
}

.rectangleProfileWrapper{
  margin-top: 124px;
}

.rectangleProfile{
  width: 238px;
  height: 228px;
  margin-left: 50px;
}

.wrapperInputClockIn{
  margin-top: 50px;
}

.wrapperStatus{
  position: relative;
  margin-top: 110px;
  margin-left: 230px;
}

.onTimeStatus{
  padding: 10px 10px;
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
display: flex;
align-items: center;
text-align: center;
color: #000000;
background: #D9E4F5;
border-radius: 6px;
}

.lateStatus{
  padding: 10px 10px;
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
display: flex;
align-items: center;
text-align: center;
color: #000000;
background: #FCDEE2;
border-radius: 6px;
}

.wrapperDescription{
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.wrapperDescription2{
  display: none;
}

.labelDescription {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
color: #B0B0B0;
}

.textDescription{
  font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 27px;
color: #000000;

}


/* Right*/
.rightContainer {
  width: 35%;
  background-color: rgba(0, 76, 190, 0.02);;
  padding: 10px;
  border-radius: 8px;
  height: 600px;
}

.rightTitle {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
}

.calendar {
  width: 302px; 
   height: 288px;
}
@media screen and (max-width: 1024px) {
  .container {
    width: 1150px;
  }
}
