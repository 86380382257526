@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.wrapper {
  display: flex;
  flex-direction: row;
  gap: 50px;
}

.title {
  font-family: "Poppins", sans-serif;
  width: 522px;
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 57px;
  background: linear-gradient(
    101.11deg,
    #0763b0 8.67%,
    #379ef5 89.51%,
    #379ef5 89.51%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.subTitle {
  width: 500px;
  height: 135px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}

.button {
  width: 190px;
  height: 50px;
  background: #004cbc;
  border-radius: 12px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

@media screen and (max-width: 768px) {
  .wrapper {
  flex-direction: column;
  gap: 0;
  }

  .wrapperLeft{
    display:flex ;
    justify-content: center;
  }
  
  .title {
  width: 100%;
  font-size: 32px;
  line-height: 48px;
  margin-bottom: 24px;
  text-align: center;
  }
  
  .subTitle {
  width: 100%;
  height: auto;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 24px;
  text-align: center;
  }
  
  .button {
  width: 100%;
  height: 40px;
  font-size: 14px;
  line-height: 21px;
  border-radius: 8px;
  margin-bottom: 24px;
  }
  }