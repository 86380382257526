.btn {
    border: none;
    border-radius: 5px;
}

.btn--size-sm {
    padding: 2px 10px;
    font-size: small
}

.btn--size-md {
    padding: 4px 12px;
    font-size: medium
}

.btn--size-lg {
    padding: 6px 14px;
    font-size: large
}

.btn--type-primary {
    background: #00bcd4;
}

.btn--type-secondary {
    background: #000;
    color: #fff;
}

.btn--type-danger {
    background: red;
}

.btn--type-warning {
    background: yellow;
}

.btn--type-success {
    background: greenyellow;
}

.btn--type-info {
    background: blueviolet;
}
