.wrapper {
  width: 100%;
  height: 70px;
  background: #fff;
  box-shadow: 0px 4px 10px rgba(176, 200, 234, 0.3);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 99;
  top: 0;
}

.container {
  width: 100%;
  margin: 0px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left {
  display: flex;
  align-items: center;
  gap: 8px;
}

.logo {
  width: 200px;
  height: 60px;
}

.right {
  display: flex;
  align-items: center;
  margin-right: 40px;
  gap: 20px;
}

.iconUserBox {
  text-decoration: none;
  color: #5c5c5c;
}

#iconUser {
  font-size: 24px;
}

.iconDropdown {
  width: 20px;
}

.user {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.userName {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.departmenName {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.navbarAvatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 2px;
}

.dropdownContainer {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.containerProfile {
  position: absolute;
  margin-top: 10px;
  margin-left: -40px;
  margin-right: 120px !important;
  background: #fff;
  width: 250px;
  height: 440px;
  border: 1px solid #e6e6e6;
  box-shadow: 0px -4px 10px rgba(176, 200, 234, 0.3);
  border-radius: 18px;
}

.topContainerProfle {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbarAvatarProfile {
  width: 100px;
  height: 100px;
  margin-top: 40px;
}

.userNameProfile {
  margin-top: 5px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.perusahaanNameProfile {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #000000;
}

.departmenNameProfile {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #000000;
}

.midContainerProfile {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
}

.editProfile {
  padding: 15px 25px;
  border: 1px solid #999999;
  border-radius: 12px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  align-items: center;
  text-align: center;
  color: #000000;
}

.arrowRight {
  cursor: pointer;
  padding: 15px 20px;
  color: #fff;
  background: #004cbc;
  border: 1px solid #e6e6e6;
  border-radius: 12px;
}

.bottomContainerProfile {
  display: flex;
  flex-direction: column;
}

.opsiLainnya {
  margin-left: 32px;
  margin-top: 20px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  color: #999999;
}

.bahasaProfile {
  display: flex;
  flex-direction: row;
  margin-left: 32px;
}

.bahasaProfile span {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #000000;
}

.bantuanProfile {
  display: flex;
  flex-direction: row;
  margin-left: 32px;
}

.bantuanProfile:hover {
  background: #e6edf8;
  width: 220px;
  height: 50px;
  border-radius: 12px;
}

.bantuanProfile span {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #000000;
}

.keluarProfile {
  display: flex;
  flex-direction: row;
  margin-left: 32px;
}

.keluarProfile span {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #000000;
}

@media (max-width: 900px) {
  .container {
    width: 100%;
    margin: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .left {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .dropdownContainer {
    margin-right: unset;
  }

  .logo {
    width: 150px;
    height: 60px;
  }

  .right {
    display: flex;
    align-items: center;
    margin-right: 5px;
    gap: 16px;
  }

  .userName,
  .departmenName {
    display: none;
  }

  .containerProfile {
    margin-left: -180px;
  }
}
