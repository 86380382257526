.pageTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: black;
    font-family: 'Poppins', sans-serif;
    margin-left: 50px;
  }
  
  .pageSubTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: black;
    font-family: 'Poppins', sans-serif;
    margin-left: 50px;
  }
  .detailContainer {
    margin-top: 24px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
  }
  
  
  /* main */
  .container {
    display: flex;
    flex-direction: row;
  }
  
  .leftContainer {
    width: 90%;
    min-height: calc(100vh - 60px);
    /* border: 1px solid black; */
    background-color: white;
    border-right: 2px solid #f3f4f5;
    
  }
  
  h4 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  }
  
  /* main detail */
  
  /* Perizinan Fullname */
  .perizinanFullname {
    display: flex;
    flex-direction: column;
    margin-left: 50px;
  }
  
  .inputFullname  {
    border: 1px solid #C4C4C4;
    width: 60%;
    height: 35px;
    border-radius: 12px;
    padding: 5px;
  }

  .fullnames {
    display: flex;
    flex-direction: column;
    margin-left: 50px;
  }
  
  /* Perizinan Departemen */
  .perizinanDepartemen {
    display: flex;
    flex-direction: column;
    margin-left: 50px;
  }
  
  .inputDepartemen  {
    border: 1px solid #C4C4C4;
    width: 60%;
    height: 35px;
    border-radius: 12px;
    padding: 5px;
  }
  
  /* Jenis Perizinan */
  .jenisPerizinan {
    display: flex;
    flex-direction: column;
    margin-left: 50px;
  }
  
  .inputJenisPerizinan   {
    border: 1px solid #C4C4C4;
    width: 60.5%;
    height: 40px;
    border-radius: 12px;
    padding: 5px;
  }
  
  /* Tanggal Perizinan */
  
  .waktuPerizinan {
    display: flex;
    flex-direction: row;
    margin-left: 50px;
  }
  
  .tglMulaiPerizinan{
    display: flex;
    flex-direction: column;
  }
  
  .inputTanggalMulai {
    border: 1px solid #C4C4C4;
    width: 100%;
    height: 35px;
    border-radius: 12px;
    padding: 5px;
  }
  
  .tglAkhirPerizinan{
    display: flex;
    flex-direction: column;
  }
  
  .inputTanggalAkhir {
    border: 1px solid #C4C4C4;
    width: 100%;
    height: 35px;
    border-radius: 12px;
    padding: 5px;
  }
  
  /* Lampiran Perizinan */
  .lampiran {
    display: flex;
    flex-direction: column;
    margin-left: 50px;
  }
  
  .inputLampiran   {
    border: 1px solid #C4C4C4;
    width: 60%;
    height: 35px;
    border-radius: 12px;
    padding: 5px;
  }
  
  /* Keterangan Perizinan */
  .keterangan{
    display: flex;
    flex-direction: column;
    margin-left: 50px;
  }
  
  .inputKeterangan  {
    border: 1px solid #C4C4C4;
    width: 60%;
    height: 120px;
    border-radius: 12px;
    padding: 5px;
  }
  
  label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Poppins', sans-serif;
  color: #B0B0B0;
  }
  
  .labelKeterangan {
    font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Poppins', sans-serif;
  }

  .face {
    display: flex;
    flex-direction: row;
    
}
  
.faces {
  width: 50px;
}
  
  .text {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: black;
  background-color: none;
  }
  
  .radioBtn{
  display: flex;
  flex-direction: row;
  }

  .img_gray {
    filter: grayscale(100%);
    width: 50px;
}
  
  /* Right*/
  .rightContainer {
    width: 35%;
    min-height: calc(100vh - 60px);
    background-color: rgba(0, 76, 190, 0.02);
    padding: 10px;
    /* height: 698px; */
  }
  
  .rightTitle {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
  }
  
  .calendar {
    width: 302px; 
     height: 288px;
  }
  @media screen and (max-width: 1024px) {
    .container {
      width: 1150px;
    }
  }
  