.wrapper {
  background-color: rgba(0, 0, 0, 0.5);
  /* background-color: wheat; */
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: none;
  z-index: 3;
  display: flex;
  flex-direction: column;
  z-index: 100;
}

.formWrapper {
  background-color: white;
  height: 100%;
  width: 25%;
  margin-top: auto;
  margin-left: auto;
}

.topWrapper {
  margin-top: 20px;
  color: #c4c4c4;
  width: 20px;
  height: 10px;
  margin-left: 30px;
}

.topFormWrapper h3 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
  margin-top: 30px;
  align-items: center;
  justify-content: center;
  text-align: left;
  margin-left: 30px;
}

.topcontentProfileWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.topcontentProfileWrapper .name {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}

.topcontentProfileWrapper .department {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}

.contentPerizinan {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  /* gap: 10px; */
}

.contentPerizinan label {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #b0b0b0;
  margin-left: 30px;
}

.contentPerizinan span {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #000000;
  margin-left: 30px;
}

.bottomFormWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 190px;
  margin-left: 20px;
}

.buttonAccept {
  background: #004cbc;
  border-radius: 15px;
  width: 150px;
  height: 50px;
  /* padding: 15px 45px; */
  /* margin-left: auto; */
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
}

.buttonDecline {
  background: #fff;
  border-radius: 15px;
  border: 1px solid #004cbc;
  width: 150px;
  height: 50px;
  /* margin-left: auto; */
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #004cbc;
}
