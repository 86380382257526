.wrapper {
  display: flex;
  flex-direction: row;
}

.leftContainer {
  display: flex;
  flex-direction: column;
  border-right: 5px solid #f3f4f5;
  background-color: rgba(0, 76, 190, 0.02);
  width: 270px;
  min-height: calc(108.7vh - 60px);
  position: fixed;
}

.topLeftContainer {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: center;
}

.topLeftContainer .arrowleft {
  width: 14px;
  height: 24px;
  color: #004cbc;
  margin-top: 4px;
}

.topLeftContainer .logo {
  width: 120px;
  height: 80px;
  position: absolute;
  margin-top: -24px;
  margin-left: 55px;
  background-color: #fafbfe;
}

.topLeftContainer .logo:hover {
  display: none;
}

.topLeftContainer span {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #004cbc;
  /* visibility: collapse; */
}

.topLeftContainer span:hover {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #004cbc;
}

.topSubLeftContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbarAvatarProfile {
  width: 110px;
  height: 110px;
  margin-top: 40px;
}

.userNameProfile {
  margin-top: 15px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
}

.perusahaanNameProfile {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  color: #000000;
}

.departmenNameProfile {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  color: #000000;
}

.midLeftContainer span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #999999;
  margin-left: 20px;
}

.midContentLeftContainer {
  align-items: center;
  text-align: center;
}

.iconSidebar {
  height: 20px;
  width: 20px;
  margin-left: 20px;
}

.linkSidebar {
  /* justify-content: center; */
  text-decoration: none;
  color: #181818;
  display: flex;
  align-items: center;
  gap: 15px;
  width: 226px;
  height: 50px;
  margin-left: 20px;
  text-align: left;
}

.linkSidebarActive {
  /* justify-content: center; */
  text-decoration: none;
  color: #004cbc;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  background-color: #e6edf8;
  width: 226px;
  height: 50px;
  margin-left: 20px;
  text-align: left;
}

.text {
  font-weight: 400;
  font-size: 16px;
  color: black;
  font-family: "Poppins", sans-serif;
}

.textColor {
  font-weight: 400;
  font-size: 16px;
  color: #004cbc;
  /* color: #004CBC; */
  font-family: "Poppins", sans-serif;
}

.bottomLeftContainer span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #999999;
  margin-left: 20px;
}

.rightWrapper {
  margin-left: auto;
}

@media (max-width: 768px) {
  .leftContainer {
    display: none;
  }

  .rightWrapper {
    width: 100%;
  }
}
