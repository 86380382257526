@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

* {
  border: none;
}

.login-pwa-wrapper {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  overflow: auto;
  display: flex;
  place-content: center;
  align-content: center;
  padding: 35px 0;
  height: 100%;
  margin-bottom: 20px;
  z-index: 3;
}

.login-pwa-box {
  /* background-color: #fff; */
  /* width: 600px; */
  /* margin: 160px auto; */
  height: 600px;
  /* border-radius: 18px; */
  display: grid;
  grid-template-areas: "lpb-left lpb-right";
  grid-template-columns: 1fr 1fr;
  box-shadow: 10px 10px 50px rgba(0, 76, 140, 0.1);
}

.login-pwa-wrapper .login-pwa-wrapper-left {
  grid-area: lpb-left;
  /* display: block; */
  /* flex-basis: 30%; */
  position: relative;
  /* border: 1px solid black; */
}
.login-pwa-wrapper .login-pwa-wrapper-left .lpwl-desc {
  position: absolute;
  top: 0;
  /* border: 5px solid black; */
  padding: 0px 40px;
}

.login-pwa-wrapper-left img {
  flex-basis: 100%;
  width: 400px;
  height: 600px;
  border-radius: 18px 0px 0px 18px;
}

.login-pwa-wrapper-left h2 {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 600px;
  line-height: 30px;
  text-align: center;
  margin-top: 100px;
  text-align: start;
}

.login-pwa-wrapper-left p {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 500px;
  line-height: 20px;
  font-style: italic;
}

.login-pwa-wrapper-right
.wrapper-close-login{
  position: relative;
}
.buttonCloseLogin{
  position: absolute;
  top: -60px;
  right: -160px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.login-pwa-box .login-pwa-wrapper-right {
  grid-area: lpb-right;
  padding: 0px;
  background-color: rgb(255, 255, 255);
  /* flex-basis: 70%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0px 18px 18px 0px;
}
.login-pwa-box .login-pwa-wrapper-right .form-login-pwa-wrapper {
  display: flex;
  flex-direction: column;
}
.login-pwa-box .form-login-pwa-wrapper {
  width: 80%;
  margin: 0;
  /* display: flex;
    justify-content: center;
    flex-direction: column; */
}

.login-pwa-wrapper
  .login-pwa-box
  .login-pwa-wrapper-right
  .form-login-pwa-wrapper
  form
  .input-email
  input {
  /* padding: 14px;
    border-radius: 12px;
    background: rgba(0, 76, 140, 0.1);;
    width: 92.5%;
    height: 20px;
    font-size: 16px;
    border: none;
    font-family: 'Poppins', sans-serif; */
  background: none;
  width: 90%;
  height: 100%;
  padding-left: 35px;
}
.login-pwa-wrapper
  .login-pwa-box
  .login-pwa-wrapper-right
  .form-login-pwa-wrapper
  form
  .input-password
  input {
  /* padding: 14px;
    border-radius: 12px;
    background: rgba(0, 76, 140, 0.1);;
    width: 92.5%;
    height: 20px;
    font-size: 16px;
    border: none;
    font-family: 'Poppins', sans-serif; */
  background: none;
  width: 90%;
  height: 100%;
  padding-left: 35px;
}
.login-pwa-wrapper
  .login-pwa-box
  .login-pwa-wrapper-right
  .form-login-pwa-wrapper
  form
  .input-email
  input:focus {
  border: none;
  outline: none;
}
.login-pwa-wrapper
  .login-pwa-box
  .login-pwa-wrapper-right
  .form-login-pwa-wrapper
  form
  .input-password
  input:focus {
  border: none;
  outline: none;
}

.login-pwa-wrapper
  .login-pwa-box
  .login-pwa-wrapper-right
  .form-login-pwa-wrapper
  form
  .input-email {
  border-radius: 12px;
  height: 50px;
  background: rgba(0, 76, 140, 0.1);
  width: 100%;
  font-size: 16px;
  border: none;
  font-family: "Poppins", sans-serif;
  position: relative;
}
.login-pwa-wrapper
  .login-pwa-box
  .login-pwa-wrapper-right
  .form-login-pwa-wrapper
  form
  .input-email
  i {
  position: absolute;
  left: 0;
  top: 0;
  margin-left: 10px;
  line-height: 50px;
  color: #c4c4c4;
}
.login-pwa-wrapper
  .login-pwa-box
  .login-pwa-wrapper-right
  .form-login-pwa-wrapper
  form
  .input-password {
  border-radius: 12px;
  height: 50px;
  background: rgba(0, 76, 140, 0.1);
  width: 100%;
  font-size: 16px;
  border: none;
  font-family: "Poppins", sans-serif;
  position: relative;
}
.login-pwa-wrapper
  .login-pwa-box
  .login-pwa-wrapper-right
  .form-login-pwa-wrapper
  form
  .input-password
  i {
  position: absolute;
  left: 0;
  top: 0;
  margin-left: 10px;
  line-height: 50px;
  color: #c4c4c4;
}
.login-pwa-wrapper
  .login-pwa-box
  .login-pwa-wrapper-right
  .form-login-pwa-wrapper
  form
  .input-password
  #mata {
  position: absolute;
  left: 0;
  top: 0;
  margin-left: 290px;
  line-height: 50px;
  color: #c4c4c4;
}
.form-login-pwa-wrapper .button-login-pwa {
  width: 100%;
  height: 50px;
  font-size: 20px;
  color: white;
  background-color: #c4c4c4;
  padding: 12px;
  border-radius: 12px;
  font-weight: 400;
  border: none;
  margin: auto;
}

.form-login-pwa-wrapper .button-login-pwa-active {
  cursor: pointer;
  color: white;
  background-color: #004cbc;
  margin: auto;
}

.login-pwa-box .login-pwa-wrapper-right .form-login-pwa-wrapper img {
  display: flex;
  justify-self: center;
  align-self: center;
  width: 224.72px;
  height: 58.83px;
}

/* .form-login-pwa-wrapper p {
    font-size: 16px;
    color: black;
    justify-content: center;
    font-weight: 400;
    border: none;
} */
.login-pwa-box
  .login-pwa-wrapper-right
  .form-login-pwa-wrapper
  form
  .login-pwa-lupa-password {
  color: #1277ea;
  display: flex;
  justify-content: end;
}
.login-pwa-box .login-pwa-wrapper-right .form-login-pwa-wrapper form {
  display: flex;
  flex-direction: column;
}

.login-pwa-box .login-pwa-wrapper-right .form-login-pwa-wrapper p {
  color: black;
  font-weight: 400;
  margin-top: 3px;
  font-family: "Poppins", sans-serif;
  text-align: center;
  font-size: 14px;
}

.login-pwa-box .login-pwa-wrapper-right .form-login-pwa-wrapper h1 {
  margin: 0;
  font-size: 35px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  text-align: center;
}

@media only screen and (max-width: 900px) {
  .login-pwa-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .login-pwa-box {
    display: flex;
    flex-direction: column;
    mix-blend-mode: normal;
    /* overflow: scroll; */
    box-shadow: none;
  }

  .login-pwa-wrapper .login-pwa-wrapper-right {
    margin-top: -45px !important;
    border-radius: 50px 0 0 0;
    position: relative;
    height: 100% !important;
  }

  .login-pwa-wrapper .login-pwa-wrapper-left img {
    height: 360px;
    width: 100%;
    top: 0;
    margin-top: -195px;
    border-radius: 0px;
    /* position: fixed; */
  }
  .login-pwa-wrapper-left h2 {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 25px;
    font-weight: 600px;
    line-height: 30px;
    text-align: start;
    margin-top: -40px;
  }

  .login-pwa-wrapper-left p {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-weight: 500px;
    line-height: 20px;
    font-style: italic;
  }

  .login-pwa-wrapper-left .lpwl-desc {
    margin-top: auto;
  }

  .login-pwa-box .login-pwa-wrapper-right .form-login-pwa-wrapper h1 {
    margin: 0;
    font-size: 25px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    text-align: center;
  }
  .login-pwa-wrapper
    .login-pwa-box
    .login-pwa-wrapper-right
    .form-login-pwa-wrapper
    form
    .input-password
    #mata {
    position: absolute;
    left: 0;
    top: 0;
    margin-left: 260px;
    line-height: 50px;
    color: #c4c4c4;
  }

  .login-pwa-box .form-login-pwa-wrapper {
    width: 80%;
    margin-top: 30px;
  }
  .login-pwa-box .login-pwa-wrapper-right {
    grid-area: lpb-right;
    padding: 0px;
    /* background-color: rgb(255, 255, 255); */
    /* flex-basis: 70%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -25px;
    /* position: fixed; */
    /* border: 1px solid black; */
  }

  .login-pwa-wrapper-right
.wrapper-close-login{
  position: relative;
}
.buttonCloseLogin{
  position: absolute;
  top: 30px;
  right: -180px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
}

