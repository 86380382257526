.container {
  display: flex;
  /* height: 100%; */
}

.controls {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 20%;
  /* padding: 1rem; */
  background: #fff;
  color: black;
  margin-left: 30px;
}

.controls input {
  border: none;
}

.mapContainer {
  width: 100%;
  height: 100vh;
}

.map {
  width: 80%;
  height: 100vh;
}

.highlight {
  font-size: 1.25rem;
  font-weight: bold;
}

.combobox-input {
  width: 100%;
  padding: 0.5rem;
}

.topLeftContainer {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: center;
}

.topLeftContainer .arrowleft {
  width: 14px;
  height: 24px;
  color: #004cbc;
  margin-top: 4px;
}

.topLeftContainer .logo {
  width: 120px;
  height: 80px;
  position: absolute;
  margin-top: -24px;
  margin-left: 55px;
  background-color: #fafbfe;
}

.topLeftContainer .logo:hover {
  display: none;
}

.topLeftContainer span {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #004cbc;
  /* visibility: collapse; */
}

.topLeftContainer span:hover {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #004cbc;
}

.titleLocation {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #bfbfbf;
}

.subTitleLocation {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}

.btnClockIn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 35px;
  background: #004cbc;
  border-radius: 12px;
  color: white;
}

@media (max-width: 768px) {
  .map {
    width: 50%;
  }
}
