.pageTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: black;
  font-family: "Poppins", sans-serif;
  margin-left: 50px;
}

.pageSubTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: black;
  font-family: "Poppins", sans-serif;
  margin-left: 50px;
}

.UserListContent {
  width: 100%;
  margin-top: 20px;
}

.titleListUser {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titleTextMain {
  font-weight: 700;
  font-size: 20px;
  color: #181818;
}

.titleDesc {
  margin-top: 8px;
  font-weight: 500;
  font-size: 14px;
  color: #5c5c5c;
}

.container {
  max-width: 100%;
  margin-top: 8px;
}

.zeroData {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #000000;
}

.table {
  margin-top: 50px;
  /* border-radius: 20px 20px 0 0 ; */
  margin-left: 50px;
  border: 1px solid #c4c4c4;
  border-collapse: collapse;
  width: 90%;
}
/* 
.head{
  border-radius: 20px 20px 20px 20px;
  border: 1px solid black;
} */

.th {
  font-weight: 400;
  font-size: 16px;
  color: white;
  padding: 20px;
  text-align: center;
}

.tr {
  background: #004cbc;
}

.trBody {
  border: 1px solid #c4c4c4;
}

.td {
  vertical-align: middle;
  font-size: 14px;
  color: #181818;
  text-align: center;
  border: 1px solid #c4c4c4;
}

.isi {
  padding: 12px 12px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}

.isi:nth-child(even) {
  background-color: rgba(0, 76, 190, 0.02);
}

.terlambat {
  color: red;
}

.input {
  display: flex;
  flex-direction: row;
  margin-left: 50px;
}

.inputSearch {
  border-radius: 12px;
  height: 30px;
  background-color: #ffffff;
  border: 1px solid #c4c4c4;
  width: 350px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  padding: 5px;
}

.inputPilihDepartemen {
  border-radius: 12px;
  height: 30px;
  background-color: #ffffff;
  border: 1px solid #c4c4c4;
  width: 100%;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  position: relative;
  padding: 5px;
}
.inputAbsensiHarian {
  border-radius: 12px;
  height: 30px;
  background-color: #ffffff;
  border: 1px solid #c4c4c4;
  width: 100%;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  position: relative;
  padding: 5px;
}

.contentPerizinan {
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  gap: 30px;
  margin-left: 50px;
}

.link {
  text-decoration: none;
}

.licontentPerizinan {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.licontentPerizinan:active {
  color: #004cbc;
}

/* responsive */
@media screen and (max-width: 1024px) {
  .container {
    width: 1000px;
  }
}
