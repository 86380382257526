.pageTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: black;
  font-family: "Poppins", sans-serif;
  margin-left: 50px;
}

.detailContainer {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

/* main */
.container {
  display: flex;
  bottom: 50px;
}
.leftContainerWrapper {
  width: 100%;
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  border-right: 2px solid #f3f4f5;
  /* height: 720px; */
  height: 100%;
  bottom: 50px;
}

.leftContainer {
  width: 100%;
  display: flex;
  bottom: 100px;
  flex-direction: row;
}

h4 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

/* main detail */

/* Perizinan Fullname */
.perizinanFullname {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.inputFullname {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  width: 100%;
  color: black;
}

/* Perizinan Departemen */
.perizinanDepartemen {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.inputDepartemen {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  width: 100%;
  color: black;
}

/* Tanggal Clock In */
.tanggalClockIn {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.inputTanggalClockIn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  width: 100%;
  color: black;
}

/* Jam Masuk */
.jamMasuk {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.inputJamMasuk {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  width: 100%;
  color: black;
}

label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: "Poppins", sans-serif;
  color: #b0b0b0;
}

.wrapperDescription {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.labelDescription {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.inputDescription {
  width: 706px;
  height: 105px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  padding: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: black;
}

.wrapperLokasi {
  display: flex;
  flex-direction: column;
}

.titleLokasi {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #b0b0b0;
  margin-left: 50px;
}

.subTitleLocakasi {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  width: 80%;
  margin-left: 50px;
}

.wrapperbtnClockIn {
  display: flex;
  flex-direction: row;

  bottom: 50px;
}

.btnBackClockIn {
  background: #ffffff;
  border: 1px solid #004cbc;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-left: 50px;
  padding: 12px 45px;
  border-radius: 12px;
}

.btnClockIn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 45px;
  background: #004cbc;
  border-radius: 12px;
  color: white;
}

.btnDisable {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 45px;
  background: #c4c4c4;
  border-radius: 12px;
  color: white;
}

.rectangleProfileWrapper {
  margin-top: 124px;
}

.rectangleProfile {
  width: 238px;
  height: 228px;
  margin-left: 50px;
}

.wrapperInputClockIn {
  margin-top: 50px;
}

/* Right*/
.rightContainer {
  width: 35%;
  background-color: rgba(0, 76, 190, 0.02);
  padding: 10px;
  border-radius: 8px;
  height: 100%;
}

.rightTitle {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
}

.calendar {
  width: 302px;
  height: 288px;
}
@media screen and (max-width: 1024px) {
  /* .container {
    width: 1150px;
  } */
}
@media screen and (max-width: 728px) {
  .leftContainer {
    flex-direction: column;
  }

  .rectangleProfileWrapper {
    margin-top: 70px;
  }

  .rectangleProfile {
    width: 150px;
    height: 150px;
    margin-left: 50px;
  }

  .container {
    flex-direction: column;
    margin-bottom: 100px;
  }

  .inputDescription {
    width: 80%;
  }

  .btnBackClockIn {
    margin-left: 25px;
  }

  .wrapperInputClockIn {
    margin-top: 20px;
  }
}
