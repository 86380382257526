.pageTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: black;
  font-family: "Poppins", sans-serif;
  margin-left: 50px;
}

.inputTanggalClockIn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;

  color: black;
}

.detailContainer {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
}

/* main */
.container {
  display: flex;
  flex-direction: column;
}
.leftContainerWrapper {
  width: 100%;
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  border-right: 2px solid #f3f4f5;
  /* height: 720px; */
}

.wrapperStatus {
  position: relative;

  margin-right: 20px;
  flex: 1;
}

.onTimeStatus {
  padding: 10px 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #000000;
  background: #d9e4f5;
  border-radius: 6px;
}

.lateStatus {
  padding: 10px 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  background: #fcdee2;
  border-radius: 6px;
}

.leftContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
}

h4 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

/* main detail */

/* Perizinan Fullname */
.perizinanFullname {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.inputFullname {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  width: 100%;
  color: black;
}

/* Perizinan Departemen */
.perizinanDepartemen {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.inputDepartemen {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  width: 100%;
  color: black;
}

/* Tanggal Clock In */
.tanggalClockIn {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

/* Jam Masuk */
.jamMasuk {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.inputJamMasuk {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  width: 100%;
  color: black;
}

label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: "Poppins", sans-serif;
  color: #b0b0b0;
}

.btnClockIn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-left: 50px;
  padding: 12px 45px;
  background: #004cbc;
  border-radius: 12px;
  color: white;
}

.rectangleProfileWrapper {
  margin-top: 124px;
}

.rectangleProfile {
  width: 238px;
  height: 228px;
  margin-left: 50px;
}

.wrapperInputClockIn {
  margin-top: 50px;
}

/* Right*/
.rightContainer {
  width: 35%;
  background-color: rgba(0, 76, 190, 0.02);
  padding: 10px;
  border-radius: 8px;
  height: 600px;
}

.rightTitle {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
}

.calendar {
  width: 302px;
  height: 288px;
}
@media screen and (max-width: 1024px) {
  .container {
    /* width: 1150px; */
  }
}

/* Styling untuk lebar layar kurang dari atau sama dengan 768px */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .wrapperInputClockIn {
    margin-top: 20px;
  }

  .rectangleProfile {
    width: 150px;
    height: 150px;
  }

  .leftContainerWrapper {
    border-right: none;
    height: auto;
  }
  .leftContainer {
    flex-direction: column;
  }
  .perizinanFullname,
  .perizinanDepartemen,
  .tanggalClockIn,
  .jamMasuk {
    margin-bottom: 5px;
  }
  /* .inputTanggalClockIn {
    width: 100%;
  } */

  .rectangleProfileWrapper {
    margin-top: 24px;
  }
  .rightContainer {
    width: 100%;
    margin-top: 24px;
    height: auto;
  }
  .calendar {
    display: none;
  }
}
