.pageTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: black;
    font-family: 'Poppins', sans-serif;
    margin-left: 50px;
  }
  

  .detailContainer {
    margin-top: 24px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
  }
  
  
  /* main */
  .container {
    display: flex;
  
  }
  
  .leftContainer {
    width: 100%;
    /* border: 1px solid black; */
    border-right: 2px solid #f3f4f5;
    min-height: calc(100vh - 60px);
  }
  
  h4 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  
  }
  
  /* main detail */
  
  /* Perizinan Fullname */
  .perizinanFullname {
    display: flex;
    flex-direction: column;
    margin-left: 50px;
  }
  
  .inputFullname  {
    border: 1px solid #C4C4C4;
    width: 60%;
    height: 35px;
    border-radius: 12px;
    padding: 5px;
    color: #c4c4c4;
  }
  
  /* Perizinan Departemen */
  .perizinanDepartemen {
    display: flex;
    flex-direction: column;
    margin-left: 50px;
  }
  
  .inputDepartemen  {
    border: 1px solid #C4C4C4;
    width: 60%;
    height: 35px;
    border-radius: 12px;
    padding: 5px;
    color: #c4c4c4;
  }
  
  /* Tanggal Clock In */
  .tanggalClockIn {
    display: flex;
    flex-direction: column;
    margin-left: 50px;
  }
  
  .inputTanggalClockIn   {
    border: 1px solid #C4C4C4;
    width: 60%;
    height: 35px;
    border-radius: 12px;
    padding: 5px;
  }
  
  /* Jam Masuk */
  .jamMasuk{
    display: flex;
    flex-direction: column;
    margin-left: 50px;
  }
  
  .inputJamMasuk  {
    border: 1px solid #C4C4C4;
    width: 20%;
    height: 35px;
    border-radius: 12px;
    padding: 5px;
  }

  
/* Keterangan Perizinan */
.keterangan{
    display: flex;
    flex-direction: column;
    margin-left: 50px;
  }
  
  .inputKeterangan  {
    border: 1px solid #C4C4C4;
    width: 70%;
    height: 200px;
    border-radius: 12px;
    padding: 5px;
  }
  
  
  label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Poppins', sans-serif;
  }
  
  
  /* Right*/
  .rightContainer {
    width: 35%;
    background-color: rgba(0, 76, 190, 0.02);;
    padding: 10px;
    border-radius: 8px;
    min-height: calc(100vh - 60px);
  }
  
  .rightTitle {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
  }
  
  .calendar {
    width: 302px; 
     height: 288px;
  }
  @media screen and (max-width: 1024px) {
    .container {
      width: 1150px;
    }
  }
  