@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

* {
  border: none;
}

.reg-karyawan-wrapper {
  /* position: fixed; */
  /* height: 100%; */
  width: 100%;
  top: 0;
  left: 0;
  /* overflow: scroll; */
  display: flex;
  /* place-content: center;
        align-content: center; */
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: black;
}

.reg-karyawan-box {
  background-color: black;
  /* width: 600px; */
  /* margin: 160px auto; */
  border-radius: 5px;
  /* display: grid;
        grid-template-areas: "lpb-left lpb-right";
        grid-template-columns: 1fr 1fr; */
  box-shadow: 10px 10px 50px rgba(0, 76, 140, 0.1);
  /* padding-bottom: 50px; */
}

/* CONTENT LEFT */
.reg-karyawan-wrapper .reg-karyawan-wrapper-left {
  grid-area: lpb-left;
  /* display: block; */
  /* flex-basis: 30%; */
  position: relative;
  /* border: 1px solid black; */
}

.reg-karyawan-wrapper .reg-karyawan-wrapper-left .reg-desc {
  position: absolute;
  top: 0;
  /* border: 5px solid black; */
  padding: 0px 40px;
}

.reg-karyawan-wrapper-left img {
  flex-basis: 100%;
  width: 440px;
  height: 1064px;
}

.reg-karyawan-wrapper-left h2 {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 600px;
  line-height: 30px;
  text-align: center;
  margin-top: 100px;
  text-align: start;
}

.reg-karyawan-wrapper-left p {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 500px;
  line-height: 20px;
  font-style: italic;
}

/* CONTENT RIGHT */
.reg-karyawan-box .reg-karyawan-wrapper-right {
  grid-area: lpb-right;
  padding: 0px;
  background-color: rgb(255, 255, 255);
  /* flex-basis: 70%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.reg-karyawan-box .reg-karyawan-wrapper-right .form-reg-karyawan-wrapper {
  display: flex;
  flex-direction: column;
}
.reg-karyawan-box .form-reg-karyawan-wrapper {
  width: 80%;
  margin: 0;
  /* display: flex;
        justify-content: center;
        flex-direction: column; */
}

/* CONTENT FULLNAME */
.reg-karyawan-wrapper
  .reg-karyawan-box
  .reg-karyawan-wrapper-right
  .form-reg-karyawan-wrapper
  form
  .input-fullname-karyawan
  input {
  /* padding: 14px
        border-radius: 12px;
        background: rgba(0, 76, 140, 0.1);;
        width: 92.5%;
        height: 20px;
        font-size: 16px;
        border: none;
        font-family: 'Poppins', sans-serif; */
  background: none;
  width: 90%;
  height: 100%;
  padding-left: 35px;
}

/* CONTENT EMAIL */
.reg-karyawan-wrapper
  .reg-karyawan-box
  .reg-karyawan-wrapper-right
  .form-reg-karyawan-wrapper
  form
  .input-email-karyawan
  input {
  /* padding: 14px
        border-radius: 12px;
        background: rgba(0, 76, 140, 0.1);;
        width: 92.5%;
        height: 20px;
        font-size: 16px;
        border: none;
        font-family: 'Poppins', sans-serif; */
  background: none;
  width: 90%;
  height: 100%;
  padding-left: 35px;
}
.reg-karyawan-wrapper
  .reg-karyawan-box
  .reg-karyawan-wrapper-right
  .form-reg-karyawan-wrapper
  form
  .input-email-karyawan
  input:focus {
  /* border: 1px solid blue; */
  /* border-radius: 10px; */
  outline: none;
}
.reg-karyawan-wrapper
  .reg-karyawan-box
  .reg-karyawan-wrapper-right
  .form-reg-karyawan-wrapper
  form
  .input-email-karyawan
  i:focus {
  color: blue;
}

/* CONTENT TELEPON */
.reg-karyawan-wrapper
  .reg-karyawan-box
  .reg-karyawan-wrapper-right
  .form-reg-karyawan-wrapper
  form
  .input-telepon-karyawan
  input {
  /* padding: 14px
        border-radius: 12px;
        background: rgba(0, 76, 140, 0.1);;
        width: 92.5%;
        height: 20px;
        font-size: 16px;
        border: none;
        font-family: 'Poppins', sans-serif; */
  background: none;
  width: 90%;
  height: 100%;
  padding-left: 35px;
}

/* CONTENT PASSWORD */
.reg-karyawan-wrapper
  .reg-karyawan-box
  .reg-karyawan-wrapper-right
  .form-reg-karyawan-wrapper
  form
  .input-password-karyawan
  input {
  /* padding: 14px
        border-radius: 12px;
        background: rgba(0, 76, 140, 0.1);;
        width: 92.5%;
        height: 20px;
        font-size: 16px;
        border: none;
        font-family: 'Poppins', sans-serif; */
  background: none;
  width: 90%;
  height: 100%;
  padding-left: 35px;
}

.reg-karyawan-wrapper
  .reg-karyawan-box
  .reg-karyawan-wrapper-right
  .form-reg-karyawan-wrapper
  form
  .input-konfirmasi-password-karyawan
  input {
  /* padding: 14px
        border-radius: 12px;
        background: rgba(0, 76, 140, 0.1);;
        width: 92.5%;
        height: 20px;
        font-size: 16px;
        border: none;
        font-family: 'Poppins', sans-serif; */
  background: none;
  width: 90%;
  height: 100%;
  padding-left: 35px;
}

/* CONTENT KODE PERUSAHAAN */
.reg-karyawan-wrapper
  .reg-karyawan-box
  .reg-karyawan-wrapper-right
  .form-reg-karyawan-wrapper
  form
  .input-kode-perusahaan
  input {
  /* padding: 14px
        border-radius: 12px;
        background: rgba(0, 76, 140, 0.1);;
        width: 92.5%;
        height: 20px;
        font-size: 16px;
        border: none;
        font-family: 'Poppins', sans-serif; */
  background: none;
  width: 90%;
  height: 100%;
  padding-left: 35px;
}
.reg-karyawan-wrapper
  .reg-karyawan-box
  .reg-karyawan-wrapper-right
  .form-reg-karyawan-wrapper
  form
  .input-password-karyawan
  input:focus {
  border: none;
  outline: none;
}
.reg-karyawan-wrapper
  .reg-karyawan-box
  .reg-karyawan-wrapper-right
  .form-reg-karyawan-wrapper
  form
  .input-konfirmasi-password-karyawan
  input:focus {
  border: none;
  outline: none;
}
.reg-karyawan-wrapper
  .reg-karyawan-box
  .reg-karyawan-wrapper-right
  .form-reg-karyawan-wrapper
  form
  .input-fullname-karyawan
  input:focus {
  border: none;
  outline: none;
}
.reg-karyawan-wrapper
  .reg-karyawan-box
  .reg-karyawan-wrapper-right
  .form-reg-karyawan-wrapper
  form
  .input-telepon-karyawan
  input:focus {
  border: none;
  outline: none;
}
.reg-karyawan-wrapper
  .reg-karyawan-box
  .reg-karyawan-wrapper-right
  .form-reg-karyawan-wrapper
  form
  .input-kode-perusahaan
  input:focus {
  border: none;
  outline: none;
}

.reg-karyawan-wrapper
  .reg-karyawan-box
  .reg-karyawan-wrapper-right
  .form-reg-karyawan-wrapper
  form
  .input-fullname-karyawan {
  border-radius: 12px;
  height: 50px;
  background: rgba(0, 76, 140, 0.1);
  width: 100%;
  font-size: 16px;
  border: none;
  font-family: "Poppins", sans-serif;
  position: relative;
}
.reg-karyawan-wrapper
  .reg-karyawan-box
  .reg-karyawan-wrapper-right
  .form-reg-karyawan-wrapper
  form
  .input-fullname-karyawan
  i {
  position: absolute;
  left: 0;
  top: 0;
  margin-left: 10px;
  line-height: 50px;
  color: #c4c4c4;
}

.reg-karyawan-wrapper
  .reg-karyawan-box
  .reg-karyawan-wrapper-right
  .form-reg-karyawan-wrapper
  form
  .input-email-karyawan {
  border-radius: 12px;
  height: 50px;
  background: rgba(0, 76, 140, 0.1);
  width: 100%;
  font-size: 16px;
  border: none;
  font-family: "Poppins", sans-serif;
  position: relative;
}
.reg-karyawan-wrapper
  .reg-karyawan-box
  .reg-karyawan-wrapper-right
  .form-reg-karyawan-wrapper
  form
  .input-email-karyawan
  i {
  position: absolute;
  left: 0;
  top: 0;
  margin-left: 10px;
  line-height: 50px;
  color: #c4c4c4;
}

.reg-karyawan-wrapper
  .reg-karyawan-box
  .reg-karyawan-wrapper-right
  .form-reg-karyawan-wrapper
  form
  .input-telepon-karyawan {
  border-radius: 12px;
  height: 50px;
  background: rgba(0, 76, 140, 0.1);
  width: 100%;
  font-size: 16px;
  border: none;
  font-family: "Poppins", sans-serif;
  position: relative;
}
.reg-karyawan-wrapper
  .reg-karyawan-box
  .reg-karyawan-wrapper-right
  .form-reg-karyawan-wrapper
  form
  .input-telepon-karyawan
  i {
  position: absolute;
  left: 0;
  top: 0;
  margin-left: 10px;
  line-height: 50px;
  color: #c4c4c4;
}
.reg-karyawan-wrapper
  .reg-karyawan-box
  .reg-karyawan-wrapper-right
  .form-reg-karyawan-wrapper
  form
  .input-password-karyawan {
  border-radius: 12px;
  height: 50px;
  background: rgba(0, 76, 140, 0.1);
  width: 100%;
  font-size: 16px;
  border: none;
  font-family: "Poppins", sans-serif;
  position: relative;
}
.reg-karyawan-wrapper
  .reg-karyawan-box
  .reg-karyawan-wrapper-right
  .form-reg-karyawan-wrapper
  form
  .input-password-karyawan
  i {
  position: absolute;
  left: 0;
  top: 0;
  margin-left: 10px;
  line-height: 50px;
  color: #c4c4c4;
}
.reg-karyawan-wrapper
  .reg-karyawan-box
  .reg-karyawan-wrapper-right
  .form-reg-karyawan-wrapper
  form
  .input-konfirmasi-password-karyawan {
  border-radius: 12px;
  height: 50px;
  background: rgba(0, 76, 140, 0.1);
  width: 100%;
  font-size: 16px;
  border: none;
  font-family: "Poppins", sans-serif;
  position: relative;
}
.reg-karyawan-wrapper
  .reg-karyawan-box
  .reg-karyawan-wrapper-right
  .form-reg-karyawan-wrapper
  form
  .input-konfirmasi-password-karyawan
  i {
  position: absolute;
  left: 0;
  top: 0;
  margin-left: 10px;
  line-height: 50px;
  color: #c4c4c4;
}

.reg-karyawan-wrapper
  .reg-karyawan-box
  .reg-karyawan-wrapper-right
  .form-reg-karyawan-wrapper
  form
  .input-kode-perusahaan {
  border-radius: 12px;
  height: 50px;
  background: rgba(0, 76, 140, 0.1);
  width: 100%;
  font-size: 16px;
  border: none;
  font-family: "Poppins", sans-serif;
  position: relative;
}
.reg-karyawan-wrapper
  .reg-karyawan-box
  .reg-karyawan-wrapper-right
  .form-reg-karyawan-wrapper
  form
  .input-kode-perusahaan
  i {
  position: absolute;
  left: 0;
  top: 0;
  margin-left: 10px;
  line-height: 50px;
  color: #c4c4c4;
}

.reg-karyawan-wrapper
  .reg-karyawan-box
  .reg-karyawan-wrapper-right
  .form-reg-karyawan-wrapper
  form
  .selectInputDepartemen {
  border-radius: 12px;
  height: 50px;
  background: rgba(0, 76, 140, 0.1);
  width: 100%;
  font-size: 16px;
  border: none;
  font-family: "Poppins", sans-serif;
  position: relative;
}
.reg-karyawan-wrapper
  .reg-karyawan-box
  .reg-karyawan-wrapper-right
  .form-reg-karyawan-wrapper
  form
  .input-kode-perusahaan
  i {
  position: absolute;
  left: 0;
  top: 0;
  margin-left: 10px;
  line-height: 50px;
  color: #c4c4c4;
}

.reg-karyawan-wrapper
  .reg-karyawan-box
  .reg-karyawan-wrapper-right
  .form-reg-karyawan-wrapper
  form
  .input-password-karyawan
  #mata {
  position: absolute;
  left: 0;
  top: 0;
  margin-left: 320px;
  line-height: 50px;
  color: #c4c4c4;
}
.reg-karyawan-wrapper
  .reg-karyawan-box
  .reg-karyawan-wrapper-right
  .form-reg-karyawan-wrapper
  form
  .input-konfirmasi-password-karyawan
  #mata {
  position: absolute;
  left: 0;
  top: 0;
  margin-left: 320px;
  line-height: 50px;
  color: #c4c4c4;
}
.reg-karyawan-wrapper
  .reg-karyawan-box
  .reg-karyawan-wrapper-right
  .form-reg-karyawan-wrapper
  form
  .register-checkbox {
  position: relative;
  height: 30px;
}
.reg-karyawan-wrapper
  .reg-karyawan-box
  .reg-karyawan-wrapper-right
  .form-reg-karyawan-wrapper
  form
  .register-checkbox
  input {
  width: 20px;
  height: 20px;
}
.reg-karyawan-wrapper
  .reg-karyawan-box
  .reg-karyawan-wrapper-right
  .form-reg-karyawan-wrapper
  form
  .register-checkbox
  p {
  position: absolute;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  color: #000000;
  /* left: 0; */
  margin-top: -25px;
  margin-left: 30px;
}

.reg-karyawan-wrapper
  .reg-karyawan-box
  .reg-karyawan-wrapper-right
  .form-reg-karyawan-wrapper
  form
  .register-checkbox
  p
  span {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  color: #1277ea;
}

.form-reg-karyawan-wrapper .button-reg-karyawans {
  width: 100%;
  height: 50px;
  font-size: 20px;
  color: white;
  background-color: #c4c4c4;
  padding: 12px;
  border-radius: 12px;
  font-weight: 400;
  border: none;
  margin: auto;
}

.form-reg-karyawan-wrapper .button-reg-karyawan-active {
  cursor: pointer;
  color: white;
  background-color: #004cbc;
  margin: auto;
}

.reg-karyawan-box .reg-karyawan-wrapper-right .form-reg-karyawan-wrapper img {
  display: flex;
  justify-self: center;
  align-self: center;
  width: 224.72px;
  height: 58.83px;
}

/* .form-reg-karyawan-wrapper p {
        font-size: 16px;
        color: black;
        justify-content: center;
        font-weight: 400;
        border: none;
    } */
.reg-karyawan-box
  .reg-karyawan-wrapper-right
  .form-reg-karyawan-wrapper
  form
  .reg-lupa-password {
  color: #1277ea;
  display: flex;
  justify-content: end;
}
.reg-karyawan-box .reg-karyawan-wrapper-right .form-reg-karyawan-wrapper form {
  display: flex;
  flex-direction: column;
}

.reg-karyawan-box .reg-karyawan-wrapper-right .form-reg-karyawan-wrapper p {
  color: black;
  font-weight: 400;
  margin-top: 3px;
  font-family: "Poppins", sans-serif;
  text-align: center;
  font-size: 12px;
}

.reg-karyawan-box .reg-karyawan-wrapper-right .form-reg-karyawan-wrapper h1 {
  margin: 0;
  font-size: 25px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  text-align: center;
}

.match-password {
  color: red;
  font-size: 13px;
}
.message-error {
  color: red;
  font-size: 13px;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  border-radius: 10px;
}

@media only screen and (max-width: 900px) {
  .reg-karyawan-wrapper {
    /* position: fixed; */
    /* height: 100%; */
    width: 100%;
    /* top: 0;
        left: 0; */
    /* overflow: scroll; */
    display: flex !important;
    flex-direction: column !important;
    /* place-content: center;
        align-content: center; */
    /* padding-top: 50px;
        padding-bottom: 50px;
        background-color: black;   */
  }

  .reg-karyawan-box {
    background-color: black;
    /* width: 600px; */
    /* margin: 160px auto; */
    border-radius: 5px;
    display: flex !important;
    flex-direction: column !important;
    /* display: grid;
        grid-template-areas: "lpb-left lpb-right";
        grid-template-columns: 1fr 1fr; */
    box-shadow: none !important;
    /* padding-bottom: 50px; */
  }

  .reg-karyawan-wrapper .reg-karyawan-wrapper-left {
    grid-area: lpb-left;
    /* display: block; */
    /* flex-basis: 30%; */
    position: relative;
    /* border: 1px solid black; */
  }

  .reg-karyawan-wrapper .reg-karyawan-wrapper-left .reg-desc {
    position: absolute;
    top: 0;
    /* border: 5px solid black; */
    padding: 0px 40px;
  }

  .reg-karyawan-wrapper-left img {
    width: 100% !important;
    height: 380px !important;
    margin-top: -60px;
    margin-right: auto;
  }

  .reg-karyawan-wrapper .reg-karyawan-wrapper-right {
    margin-top: -45px !important;
    border-radius: 50px 0 0 0 !important;
    position: relative;
    height: 100%;
  }

  .reg-karyawan-box .form-reg-karyawan-wrapper {
    width: 100%;
    margin-top: 30px !important;
  }

  .reg-karyawan-wrapper
    .reg-karyawan-box
    .reg-karyawan-wrapper-right
    .form-reg-karyawan-wrapper
    form
    .input-password-karyawan
    #mata {
    position: absolute;
    left: 0;
    top: 0;
    margin-left: 270px !important;
    line-height: 50px;
    color: #c4c4c4;
  }

  .reg-karyawan-wrapper
    .reg-karyawan-box
    .reg-karyawan-wrapper-right
    .form-reg-karyawan-wrapper
    form
    .input-konfirmasi-password-karyawan
    #mata {
    position: absolute;
    left: 0;
    top: 0;
    margin-left: 270px !important;
    line-height: 50px;
    color: #c4c4c4;
  }
}

.masuk {
  color: #004cbc;
  cursor: pointer;
}
