* {
    border: none;
    }
    
    .reg-karyawan-wrapper {
        /* position: fixed; */
        /* height: 100%; */
        width: 100%;
        top: 0;
        left: 0;
        /* overflow: scroll; */
        display: flex;
        place-content: center;
        align-content: center;
        padding-top: 50px;
        padding-bottom: 50px;
        background-color: #fff;  
    }
    
    .reg-karyawan-box{
        background-color: #fff;
        /* width: 600px; */
        /* margin: 160px auto; */
        border-radius: 10px !important;
        display: grid;
        grid-template-areas: "lpb-left lpb-right";
        grid-template-columns: 1fr 1fr;
        box-shadow: 10px 10px 50px rgba(0, 76, 140, 0.1);
        /* padding-bottom: 50px; */
        }
    
        /* CONTENT LEFT */
    .reg-karyawan-wrapper  .reg-karyawan-wrapper-left {
        grid-area: lpb-left;
        /* display: block; */
        /* flex-basis: 30%; */
        position: relative;
        /* border: 1px solid black; */
    }
    
    .reg-karyawan-wrapper .reg-karyawan-wrapper-left .reg-desc{
        position: absolute;
        top: 0;
        /* border: 5px solid black; */
        padding: 0px 40px;
    }
    
    .reg-karyawan-wrapper-left img {
        flex-basis: 100%;
        width: 440px;
        height: 1064px;
    
    }
    
    .reg-karyawan-wrapper-left h2 {
        color: #fff;
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-weight: 600px;
        line-height: 30px;
        text-align: center;
        margin-top: 100px;
        text-align: start;
        
    }
    
    .reg-karyawan-wrapper-left p {
        color: #fff;
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        font-weight: 500px;
        line-height: 20px;
        font-style: italic;
    }
    
    /* CONTENT RIGHT */
    .reg-karyawan-box .reg-karyawan-wrapper-right{
        grid-area: lpb-right;
        padding: 0px;
        background-color: rgb(255, 255, 255);
        /* flex-basis: 70%; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
    }
    .reg-karyawan-box .reg-karyawan-wrapper-right .form-reg-karyawan-wrapper{
    display: flex;
    flex-direction: column;
    margin-left: -30px;
    }
    .reg-karyawan-box .form-reg-karyawan-wrapper {
        width: 80%;
        margin: 0;
        /* display: flex;
        justify-content: center;
        flex-direction: column; */
    }
    
    /* CONTENT FULLNAME */
    .reg-karyawan-wrapper .reg-karyawan-box .reg-karyawan-wrapper-right .form-reg-karyawan-wrapper form .input-fullname-karyawan input {
        /* padding: 14px
        border-radius: 12px;
        background: rgba(0, 76, 140, 0.1);;
        width: 92.5%;
        height: 20px;
        font-size: 16px;
        border: none;
        font-family: 'Poppins', sans-serif; */
        background: none;
        width: 90%;
        height: 100%; 
        padding-left: 35px;
    }
    
    /* CONTENT EMAIL */
    .reg-karyawan-wrapper .reg-karyawan-box .reg-karyawan-wrapper-right .form-reg-karyawan-wrapper form .input-email-karyawan input {
        /* padding: 14px
        border-radius: 12px;
        background: rgba(0, 76, 140, 0.1);;
        width: 92.5%;
        height: 20px;
        font-size: 16px;
        border: none;
        font-family: 'Poppins', sans-serif; */
        background: none;
        width: 90%;
        height: 100%; 
        padding-left: 35px;
    }
    .reg-karyawan-wrapper .reg-karyawan-box .reg-karyawan-wrapper-right .form-reg-karyawan-wrapper form .input-email-karyawan input:focus {
        /* border: 1px solid blue; */
        /* border-radius: 10px; */
        outline: none;
    }
    .reg-karyawan-wrapper .reg-karyawan-box .reg-karyawan-wrapper-right .form-reg-karyawan-wrapper form .input-email-karyawan  i:focus {
        color: blue;
    }
    
    /* CONTENT TELEPON */
    .reg-karyawan-wrapper .reg-karyawan-box .reg-karyawan-wrapper-right .form-reg-karyawan-wrapper form .input-telepon-karyawan input {
        /* padding: 14px
        border-radius: 12px;
        background: rgba(0, 76, 140, 0.1);;
        width: 92.5%;
        height: 20px;
        font-size: 16px;
        border: none;
        font-family: 'Poppins', sans-serif; */
        background: none;
        width: 90%;
        height: 100%; 
        padding-left: 35px;
    }

    /* CONTENT PASSWORD */
    .reg-karyawan-wrapper .reg-karyawan-box .reg-karyawan-wrapper-right .form-reg-karyawan-wrapper form .input-password-karyawan input {
        /* padding: 14px
        border-radius: 12px;
        background: rgba(0, 76, 140, 0.1);;
        width: 92.5%;
        height: 20px;
        font-size: 16px;
        border: none;
        font-family: 'Poppins', sans-serif; */
        background: none;
        width: 90%;
        height: 100%; 
        padding-left: 35px;
    }

    .reg-karyawan-wrapper .reg-karyawan-box .reg-karyawan-wrapper-right .form-reg-karyawan-wrapper form .input-konfirmasi-password-karyawan input {
        /* padding: 14px
        border-radius: 12px;
        background: rgba(0, 76, 140, 0.1);;
        width: 92.5%;
        height: 20px;
        font-size: 16px;
        border: none;
        font-family: 'Poppins', sans-serif; */
        background: none;
        width: 90%;
        height: 100%; 
        padding-left: 35px;
    }

    /* CONTENT KODE PERUSAHAAN */
    .reg-karyawan-wrapper .reg-karyawan-box .reg-karyawan-wrapper-right .form-reg-karyawan-wrapper form .input-kode-perusahaan input {
        /* padding: 14px
        border-radius: 12px;
        background: rgba(0, 76, 140, 0.1);;
        width: 92.5%;
        height: 20px;
        font-size: 16px;
        border: none;
        font-family: 'Poppins', sans-serif; */
        background: none;
        width: 90%;
        height: 100%; 
        padding-left: 35px;
    }
    .reg-karyawan-wrapper .reg-karyawan-box .reg-karyawan-wrapper-right .form-reg-karyawan-wrapper form .input-password-karyawan input:focus {
        border: none;
        outline: none;
    }
    .reg-karyawan-wrapper .reg-karyawan-box .reg-karyawan-wrapper-right .form-reg-karyawan-wrapper form .input-konfirmasi-password-karyawan input:focus {
        border: none;
        outline: none;
    }
    .reg-karyawan-wrapper .reg-karyawan-box .reg-karyawan-wrapper-right .form-reg-karyawan-wrapper form .input-fullname-karyawan input:focus {
        border: none;
        outline: none;
        
    }
    .reg-karyawan-wrapper .reg-karyawan-box .reg-karyawan-wrapper-right .form-reg-karyawan-wrapper form .input-telepon-karyawan input:focus {
        border: none;
        outline: none;
    }
    .reg-karyawan-wrapper .reg-karyawan-box .reg-karyawan-wrapper-right .form-reg-karyawan-wrapper form .input-kode-perusahaan input:focus {
        border: none;
        outline: none;
    }
    
    
    .reg-karyawan-wrapper .reg-karyawan-box .reg-karyawan-wrapper-right .form-reg-karyawan-wrapper form .input-fullname-karyawan{
        border-radius: 12px;
        height: 50px;
        background: rgba(0, 76, 140, 0.1);;
        width: 100%;
        font-size: 16px;
        border: none;
        font-family: 'Poppins', sans-serif;
        position: relative;    
    }
    .reg-karyawan-wrapper .reg-karyawan-box .reg-karyawan-wrapper-right .form-reg-karyawan-wrapper form .input-fullname-karyawan i {
        position: absolute;
        left: 0;
        top: 0;
        margin-left: 10px;
        line-height: 50px;
        color:#C4C4C4;;
    }

    .reg-karyawan-wrapper .reg-karyawan-box .reg-karyawan-wrapper-right .form-reg-karyawan-wrapper form .input-email-karyawan {
        border-radius: 12px;
        height: 50px;
        background: rgba(0, 76, 140, 0.1);
        width: 100%;
        font-size: 16px;
        border: none;
        font-family: 'Poppins', sans-serif;
        position: relative;  
    }
    .reg-karyawan-wrapper .reg-karyawan-box .reg-karyawan-wrapper-right .form-reg-karyawan-wrapper form .input-email-karyawan i {
        position: absolute;
        left: 0;
        top: 0;
        margin-left: 10px;
        line-height: 50px;
        color: #C4C4C4;
    }

    .reg-karyawan-wrapper .reg-karyawan-box .reg-karyawan-wrapper-right .form-reg-karyawan-wrapper form .input-telepon-karyawan{
        border-radius: 12px;
        height: 50px;
        background: rgba(0, 76, 140, 0.1);;
        width: 100%;
        font-size: 16px;
        border: none;
        font-family: 'Poppins', sans-serif;
        position: relative;    
    }
    .reg-karyawan-wrapper .reg-karyawan-box .reg-karyawan-wrapper-right .form-reg-karyawan-wrapper form .input-telepon-karyawan i {
        position: absolute;
        left: 0;
        top: 0;
        margin-left: 10px;
        line-height: 50px;
        color:#C4C4C4;;
    }
    .reg-karyawan-wrapper .reg-karyawan-box .reg-karyawan-wrapper-right .form-reg-karyawan-wrapper form .input-password-karyawan{
        border-radius: 12px;
        height: 50px;
        background: rgba(0, 76, 140, 0.1);;
        width: 100%;
        font-size: 16px;
        border: none;
        font-family: 'Poppins', sans-serif;
        position: relative;    
    }
    .reg-karyawan-wrapper .reg-karyawan-box .reg-karyawan-wrapper-right .form-reg-karyawan-wrapper form .input-password-karyawan i {
        position: absolute;
        left: 0;
        top: 0;
        margin-left: 10px;
        line-height: 50px;
        color:#C4C4C4;;
    }
    .reg-karyawan-wrapper .reg-karyawan-box .reg-karyawan-wrapper-right .form-reg-karyawan-wrapper form .input-konfirmasi-password-karyawan{
        border-radius: 12px;
        height: 50px;
        background: rgba(0, 76, 140, 0.1);;
        width: 100%;
        font-size: 16px;
        border: none;
        font-family: 'Poppins', sans-serif;
        position: relative;    
    }
    .reg-karyawan-wrapper .reg-karyawan-box .reg-karyawan-wrapper-right .form-reg-karyawan-wrapper form .input-konfirmasi-password-karyawan i {
        position: absolute;
        left: 0;
        top: 0;
        margin-left: 10px;
        line-height: 50px;
        color:#C4C4C4;;
    }

    .reg-karyawan-wrapper .reg-karyawan-box .reg-karyawan-wrapper-right .form-reg-karyawan-wrapper form .input-kode-perusahaan {
        border-radius: 12px;
        height: 50px;
        background: rgba(0, 76, 140, 0.1);
        width: 100%;
        font-size: 16px;
        border: none;
        font-family: 'Poppins', sans-serif;
        position: relative;  
    }
    .reg-karyawan-wrapper .reg-karyawan-box .reg-karyawan-wrapper-right .form-reg-karyawan-wrapper form .input-kode-perusahaan i {
        position: absolute;
        left: 0;
        top: 0;
        margin-left: 10px;
        line-height: 50px;
        color: #C4C4C4;
    }


    .reg-karyawan-wrapper .reg-karyawan-box .reg-karyawan-wrapper-right .form-reg-karyawan-wrapper form .input-password-karyawan #mata {
        position: absolute;
        left: 0;
        top: 0;
        margin-left: 320px;
        line-height: 50px;
        color:#C4C4C4;;
    }
    .reg-karyawan-wrapper .reg-karyawan-box .reg-karyawan-wrapper-right .form-reg-karyawan-wrapper form .input-konfirmasi-password-karyawan #mata {
        position: absolute;
        left: 0;
        top: 0;
        margin-left: 320px;
        line-height: 50px;
        color:#C4C4C4;;
    }
    
    .form-reg-karyawan-wrapper .button-reg-karyawan {
        width: 100%;
        height: 50px;
        font-size: 20px;
        color: white;
        background-color: #C4C4C4;
        padding: 12px;
        border-radius: 12px;
        font-weight: 400;
        border: none;
    }
    
    .reg-karyawan-box .reg-karyawan-wrapper-right .form-reg-karyawan-wrapper img {
        display: flex;
        justify-self: center;
        align-self: center;
        width: 224.72px;
        height: 58.83px;
    } 
    
    /* .form-login-pwa-wrapper p {
        font-size: 16px;
        color: black;
        justify-content: center;
        font-weight: 400;
        border: none;
    } */
    .reg-karyawan-box .reg-karyawan-wrapper-right .form-reg-karyawan-wrapper form .reg-lupa-password {
        color: #1277EA;
        display: flex;
        justify-content: end;
    
    
    }
    .reg-karyawan-box .reg-karyawan-wrapper-right .form-reg-karyawan-wrapper form{
        display: flex;
        flex-direction: column;
    }
    
    .reg-karyawan-box .reg-karyawan-wrapper-right .form-reg-karyawan-wrapper p{
        color: black;
        font-weight: 400;
        margin-top: 3px;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        font-size: 12px;
    }
    
    .reg-karyawan-box .reg-karyawan-wrapper-right .form-reg-karyawan-wrapper h1 {
        margin: 0;
        font-size: 25px;
        font-weight: 600;
        font-family:'Poppins', sans-serif;
        text-align: center;
    }

    .match-password {
        color: red;
        font-size: 13px;
    }

    .untuk-button{
        display: flex;
        flex-direction: row;
    }

    .radioPriceBtn{
        display: flex;
        flex-direction: column;
    }

    .radioPriceBtn label{
        display: flex;
        flex-direction: row;
    }

    .radioPriceBtn input {
        width: 25px;
        height: 25px;
        margin: auto;
    }

    /*bluepayment*/
    .bluepayment{
        margin-left: 10px;
        width: 450px;
        height: 200px;
        background: linear-gradient(101.11deg, #0763B0 8.67%, #379EF5 89.51%, #379EF5 89.51%);
        border-radius: 18.5075px;
    }

    .bluepaymentform {
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
        display: flex;
        flex-direction: column;
    }
    .bluepaymenttitle{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .bluepaymenttitle h3 {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
        line-height: 37px;
        color: #FFFFFF;
    }

    .bluepaymenttitle span {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        color: #FFFFFF;
    }

    .bluepaymentcontent li {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #FFFFFF;
        text-align: start;
    }

    /*Silverpayment*/
    .silverpayment{
        margin-left: 10px;
        width: 450px;
        height: 200px;
        background: linear-gradient(101.11deg, #566B83 8.67%, #9098A1 89.51%);
        border-radius: 18.5075px;
        /* background: url(../../../assets//images/bgpayment.png); */
    }

    .silverpaymentform {
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
        display: flex;
        flex-direction: column;
    }
    .silverpaymenttitle{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .silverpaymenttitle h3 {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
        line-height: 37px;
        color: #FFFFFF;
    }

    .silverpaymenttitle span {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        color: #FFFFFF;
    }

    .silverpaymentcontent li {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #FFFFFF;
        text-align: start;
    }
    /*Gold Payment*/
    .goldpayment{
        margin-left: 10px;
        width: 450px;
        height: 200px;
        background: linear-gradient(101.11deg, #EAAA06 8.67%, #E1CD1F 89.51%);
        border-radius: 18.5075px;
    }

    .goldpaymentform {
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
        display: flex;
        flex-direction: column;
    }
    .goldpaymenttitle{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .goldpaymenttitle h3 {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
        line-height: 37px;
        color: #FFFFFF;
    }

    .goldpaymenttitle span {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        color: #FFFFFF;
    }

    .goldpaymentcontent li {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #FFFFFF;
        text-align: start;
    }

    .btnNext {
    width: 350px;
    height: 58px;
    background-color: #004cbc;
    border-radius: 15px;
    /* align-items: center;
    text-align: center; */
    font-size: 21px;
    color: white;
    text-decoration: none;
    margin: auto;
    font-family: 'Poppins', sans-serif;
    }

    .untuk-button-two{
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-decoration: none;
        margin: auto;
    }

    .backtwo {
        background-color: white;
        border: #1277EA 1px solid;
        border-radius: 15px;
        align-items: center;
        text-align: center;
        font-size: 15px;
        color: black;
        text-decoration: none;
        font-family: 'Poppins', sans-serif;
        margin: auto;
        padding: 15px 45px;
    }
    .nextthree {
        background-color: #004cbc;
        border-radius: 15px;
        align-items: center;
        text-align: center;
        font-size: 15px;
        color: white;
        text-decoration: none;
        font-family: 'Poppins', sans-serif;
        margin: auto;
        padding: 15px 55px;
    }

    .untuk-button-three{
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-decoration: none;
        margin: auto;
    }

    .btnDaftar {
        /* width: 200px;
        height: 58px; */
        padding: 16px 55px;
        background-color: #004cbc;
        border-radius: 15px;
        align-items: center;
        text-align: center;
        font-size: 15px;
        color: white;
        text-decoration: none;
        font-family: 'Poppins', sans-serif;
        margin: auto;
    }
    
    
    input:-webkit-autofill,
input:-webkit-autofill:focus {
    border-radius: 10px;
}
    
