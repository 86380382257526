.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.leftContainer {
  display: flex;
  flex-direction: column;
  margin-left: 100px;
  margin-top: 100px;
}

.imgPay {
  width: 410px;
  height: 384px;
}
.logofix {
  width: 350px;
  height: 70px;
}

h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  font-family: "Poppins", sans-serif;
}

.dropdownPayment {
  display: flex;
  flex-direction: row;
  width: 430px;
  height: 65px;
  border: #004cbc 1px solid;
  border-radius: 15px;
  align-items: center;
  align-content: center;
  box-sizing: border-box;
  cursor: pointer;
}

.dropdownPayment p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #000000;
  margin-left: 20px;
}

.iconDropdown {
  align-items: center;
  /* border: 1px solid black; */
  height: 30px;
  width: 30px;
  margin-left: 140px;
  color: #c4c4c4;
}

.iconDropdownWallet {
  align-items: center;
  /* border: 1px solid black; */
  height: 30px;
  width: 30px;
  margin-left: 300px;
  color: #c4c4c4;
  cursor: pointer;
}

.iconDropdownAlfa {
  align-items: center;
  /* border: 1px solid black; */
  height: 30px;
  width: 30px;
  margin-left: 190px;
  color: #c4c4c4;
  cursor: pointer;
}

/* .payment{
    width: 500px;
    height: 450px;
}
   */

/*Right Container*/

.rightContainer {
  display: flex;
  flex-direction: column;
  margin-right: 100px;
}

.formPayment {
  margin-top: 40px;
  margin-left: 40px;
  margin-right: 40px;
  /* justify-content: center; */
}

.DetailPayment {
  width: 550px;
  /* height: 570px; */
  height: 450px;
  background: #004cbc;
  border-radius: 0px 0px 20px 20px;
}

.title {
  font-family: "Poppins", sans-serif;
  color: white;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.titleDua {
  font-family: "Poppins", sans-serif;
  color: white;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin-top: 10px;
}

.formPaymentSatu {
  border-bottom: 2px solid white;
}

.mainDetailData {
  display: flex;
  margin-top: 16px;
  gap: 10px;
}
.mainDetailDataDua {
  display: flex;
  border-bottom: 1px solid white;
}
.mainDetailDataTiga {
  margin-top: 10px;
  display: flex;
}

.mainLeftData {
  flex: 3;
  color: white;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  font-family: "Poppins", sans-serif;
  gap: 10px;
}

.mainRightData {
  flex: 3;
  font-family: "Poppins", sans-serif;
  color: white;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  text-align: right;
  gap: 10px;
}

.mainLeftData,
.mainRightData {
  display: flex;
  flex-direction: column;
}

.btnPayment {
  width: 470px;
  height: 60px;
  align-items: center;
  background-color: white;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #004cbc;
  margin: auto;
  margin-right: auto;
  border-radius: 20px;
}

.payment {
  display: flex;
  flex-direction: row;
  width: 380px;
  height: 65px;
  border: #004cbc 1px solid;
  border-radius: 15px;
  align-items: center;
  align-content: center;
  box-sizing: border-box;
  margin-left: 50px;
}

.imgPayment {
  width: 40px;
  height: 24px;
  margin-left: 10px;
}

.namePayment {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  align-items: center;
}

.detailPayment {
  background: linear-gradient(101.11deg, #566b83 8.67%, #9098a1 89.51%);
  border-radius: 18px;
  width: 410px;
  height: 384px;
  display: flex;
  flex-direction: column;
}

.topDetailPayment {
  margin-top: 34px;
  border: 1px dashed #ffffff;
  border-width: 2px;
}
/* 
.formTopDetailPayment{
margin-top: 49px;
} */

.payment:active {
  border: 10px solid red;
}

.formTopDetailPayment h3 {
  margin-left: 40px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}

.formTopDetailPayment h2 {
  margin-left: 40px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}

.formTopDetailPayment span {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}

.eclipesleft {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-top: 115px;
  margin-left: -10px;
  position: absolute;
  background-color: white;
}

.eclipesright {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-top: 115px;
  margin-left: 396px;
  position: absolute;
  background-color: white;
}

.bottomDetailPayment h4 {
  margin-top: 20px;
  margin-left: 40px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
}

.bottomDetailPayment li {
  margin-left: 40px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
  text-align: left;
}
