.wrapper{
    display: flex;
    flex-direction: column;
    background: #004CBC;
    min-height: calc(100vh);
}
.wrapperTop{
    margin-top: 40px;
    /* padding:20px 10px 40px 0px; */
    background: #B0C8EA;
    border-radius: 20px;
    width: 1000px;
    height: 70px;
    margin-left: auto;
    margin-right: auto;
}
.wrapperTop p{
    font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 30px;
color: #000000;
align-items: center;
text-align: left;
margin-left: 20px;
margin-top: 20px;
}

.wrapperTop span {
font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 30px;
color: #000000;
margin-left: 5px;
}

.wrapperContent{
    background: white;
    border-radius: 20px;
    width: 1000px;
    height: 640px;
    margin-bottom: 40px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.imgGopay {
    margin-top: 50px;
    width: 179.88px;
    height: 65px;
}

.imgGP {
    width: 364px;
height: 364px;
}

.bottomGopayContent {
    font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 27px;
text-align: center;
color: #000000;
width: 358px;
}
